import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import BaseTab from '../BaseTab';
import BaseTabs from '../BaseTabs';
import BaseTable from '../BaseTable';
import TableSearchField from '../TableSearchField';
import BaseBtnOutlined from '../BaseBtnOutlined';
import BaseBtnFilled from '../BaseBtnFilled';

import { tbStyles } from '../_tbStyles';

const SERVER = process.env.REACT_APP_API_URL;

const TransactionsTable = () => {
  const tbClasses = tbStyles();
  let navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      const results = await axios.get(
        `${SERVER}/transactions?page=1&pageSize=10`
      );
      // setData(results.data.orders);
      console.log('TRANS', results.data);
    };

    fetchTransactions();
    return () => {};
  }, []);

  const viewDetails = (id) => {
    const selected = data.find((item) => item.id === id);
    return selected;
  };
  return (
    <>
      <BaseTable
        ariaLabel="customers list table"
        header={
          <>
            <TableCell className={tbClasses.header}>Transaction ID</TableCell>
            <TableCell className={tbClasses.header}>Username</TableCell>
            <TableCell className={tbClasses.header}>Amount</TableCell>
            <TableCell className={tbClasses.header}>Method</TableCell>
            <TableCell className={tbClasses.header}>Date</TableCell>
            <TableCell className={tbClasses.header}></TableCell>
          </>
        }
        body={
          <>
            {!data ||
              (data && data.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} className={tbClasses.empty}>
                    No data available
                  </TableCell>
                </TableRow>
              ))}

            {data.map((item, i) => (
              <TableRow key={i}>
                <TableCell>#{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>{item.method}</TableCell>
                <TableCell>{item.date} </TableCell>

                <TableCell>
                  <BaseBtnFilled fullWidth onClick={() => viewDetails(item.id)}>
                    View Details
                  </BaseBtnFilled>
                </TableCell>
              </TableRow>
            ))}
          </>
        }
      />
    </>
  );
};

export default TransactionsTable;
