import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ScheduleIcon from '@material-ui/icons/Schedule';

import UsersTable from '../../components/users/UsersTable';
import BaseStatCard from '../../components/BaseStatCard';
import { fetchDashboardWidgets } from '../../store/auth/authActions';
import TotalUsers  from  '../../assets/images/total_users.svg';
import TotalSeller   from '../../assets/images/total_seller.svg';
import TotalBuyers  from '../../assets/images/total_buyers.svg';
import BaseStatCardVoucher from '../../components/BaseStatCardVoucher';
import userMain from './userMain';

const mapState = ({ auth }) => ({
  widgetsLoading: auth.widgetsLoading,
  widgets: auth.widgets,
  widgetsErr: auth.widgetsErr,
});
const UsersHome = () => {
  const dispatch = useDispatch();
  const { widgetsLoading, widgets, widgetsErr } = useSelector(mapState);
  // users
  const [totalUsers, settotalUsers] = useState(0);
  const [totalUserPercentage, settotalUserPercentage] = useState(0)
  const [userRise, setuserRise] = useState(false)

  // sellers

  const [totalSellers, settotalSellers] = useState(0)
  const [totalSellerPercentage, settotalSellerPercentage] = useState(10)
  const [sellerRise, setsellerRise] = useState(true)

  // buyers

  const [totalBuyers, settotalBuyers] = useState(0);
  const [totalBuyersPercentages, settotalBuyersPercentages] = useState(0);
  const [buyerRise, setbuyerRise] = useState(false)


  useEffect(() => {
    dispatch(fetchDashboardWidgets());
    return () => {};
  }, []);

  return (
    <div>
      <Box mb={3}>
        <userMain></userMain>
        {widgets && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
            <BaseStatCardVoucher
              icon={TotalUsers}
              text1="Total Users"
              text2={totalUsers}
              up={userRise}
              percentage={totalUserPercentage}
            />
              {/* <BaseStatCard
                icon={<ScheduleIcon />}
                text1="Daily Active Users"
                text2="coming soon"
              /> */}
            </Grid>
            <Grid item xs={12} md={4}>
            <BaseStatCardVoucher
              icon={TotalSeller}
              text1="Total Sellers"
              text2={totalSellers}
              up={sellerRise}
              percentage={totalSellerPercentage}
            />
            </Grid>
            <Grid item xs={12} md={4}>
              {/* <BaseStatCard
                icon={TotalUsers}
                text1="Total Users "
                text2={widgets.totalUsers}
              /> */}

            <BaseStatCardVoucher
              icon={TotalBuyers}
              text1="Total Buyers"
              text2={totalBuyers}
              up={buyerRise}
              percentage={totalBuyersPercentages}
            />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box>
        <UsersTable />
      </Box>
    </div>
  );
};

export default UsersHome;
