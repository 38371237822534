import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import Paper from '@material-ui/core/Paper';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    boxShadow: '0px 3px 6px #00000012',
    borderRadius: '6px',
    padding: '16px',
  },
  primary: {
    color: '#989898',
    fontSize: '0.875rem',
  },
  secondary: {
    color: '#181818',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  image:{
    width:"65px",
 
  },
  boxd:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    background:"#F7E5E5",
   borderRadius:"4px",
   width:"55px",
   height:"22px",
   marginBottom:"1.3rem",
   marginLeft:"3px"
  },
  boxd1:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    background:"#E5F7E7",
   borderRadius:"4px",
   width:"55px",
   height:"22px",
   marginBottom:"1.3rem",
   marginLeft:"3px"
  },
  avator:{
    fontSize:"8px",
    color:"#D80000",
    maxWidth:"15px",
    paddingTop:"5px",
    letterSpacing:"-0.08px",
    font:"normal normal bold 12px/22px Lato",

    transform: "rotate(45deg)",

    


  },
  avator1:{
    fontSize:"8px",
    color:"#0C7218",
    maxWidth:"15px",
    paddingTop:"5px",
    letterSpacing:"-0.08px",
    font:"normal normal bold 12px/22px Lato",

    transform: "rotate(45deg)",

    


  },
  percentage:{
    fontSize:"14px",
    color:"#D80000",
    letterSpacing:"-0.08px",
    font:"normal normal bold 12px/22px Lato",

    
  },
  percentage1:{
    fontSize:"12px",
    color:"#0C7218",

    letterSpacing:"-0.08px",
    font:"normal normal bold 12px/22px Lato",

    
  }
});
const BaseStatCardVoucher = ({ icon, text1, text2, bgAvatar,up,percentage }) => {
  const classes = useStyles();
  
  return (
    <Paper className={classes.root}>
      <ListItem>
        <ListItemAvatar className={`${bgAvatar}`}>
          <img className={classes.image} src={icon} />
        </ListItemAvatar>
        <ListItemText
          classes={{ primary: classes.primary, secondary: classes.secondary }}
          primary={text1}
          secondary={text2}
        />
        {up ? (
          <Box className={classes.boxd1} >
          <Box>
            {/* <ArrowDownwardIcon className={classes.avator}/> */}
            <ArrowUpwardIcon className={classes.avator1} />
          </Box>
          <p className={classes.percentage1} >{percentage}</p>
        </Box>
        ):(
          <Box className={classes.boxd} >
          <Box>
            <ArrowDownwardIcon className={classes.avator}/>
            {/* <ArrowUpwardIcon className={classes.avator} /> */}
          </Box>
          <p className={classes.percentage} >{percentage}</p>
        </Box>
        )}
      </ListItem>
    </Paper>
  );
};

export default BaseStatCardVoucher;
