import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import BaseInput2 from '../../components/BaseInput2';
import BaseCard from '../../components/BaseCard';
import BaseBtn from '../../components/BaseBtn';
import { loginUser } from '../../store/auth/authActions';
const useStyles = makeStyles({
  loginBtn: {
    backgroundColor: '#4900ff',
    color: '#fff',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  loginBtnDisabled: {
    backgroundColor: '#E2E2E2',
    color: '#717171',
  },

  errorClass: {
    border: '1px solid red',
    borderRadius: '2px',
  },
});

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

const mapState = ({ auth }) => ({
  token: auth.token,
  loginErr: auth.loginErr,
  loading: auth.loading,
});
const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token, loading, loginErr } = useSelector(mapState);

  const server = process.env.REACT_APP_SERVER_URL;

  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // get auth state

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const creds = {
        username: values.email,
        password: values.password,
      };

      dispatch(loginUser(creds));
    },
  });

  if (token) {
    return <Navigate to="/" replace />;
  }
  return (
    <BaseCard>
      <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        <CardContent>
          <h2
            className="txt-ruby txt-center h2x-large-bold"
            style={{ marginBottom: '16px' }}
          >
            Welcome back!
          </h2>
          <p
            className="txt-violet txt-lato txt-center f-14"
            style={{ marginBottom: '48px' }}
          >
            Use your Lukhu email to Log In
          </p>

          <div className="mb-16">
            <BaseInput2
              id="email"
              name="email"
              type="email"
              placeholder="email *"
              value={formik.values.email}
              onChange={formik.handleChange}
              fullWidth
              className={
                formik.errors.email && formik.touched.email
                  ? classes.errorClass
                  : ''
              }
            />
            {formik.errors.email && formik.touched.email && (
              <p className="input-error">
                <small>{formik.errors.email}</small>
              </p>
            )}
          </div>

          <div className="mb-16">
            <BaseInput2
              fullWidth
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              placeholder="password *"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityIcon fontSize="small" />
                    ) : (
                      <VisibilityOffIcon fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              value={formik.values.password}
              onChange={formik.handleChange}
              className={
                formik.errors.password && formik.touched.password
                  ? classes.errorClass
                  : ''
              }
            />
            {formik.errors.password && formik.touched.password && (
              <p className="input-error">
                <small>{formik.errors.password}</small>
              </p>
            )}
          </div>

          {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link to="/auth/request_password_reset">
              <span className="f-12">Forgot password?</span>
            </Link>
          </div> */}
          <div className="flex-center mb-16">
            {loginErr && (
              <p className="input-error">Invalid login credentials!</p>
            )}
          </div>
        </CardContent>

        <CardActions>
          <BaseBtn
            type="submit"
            fullWidth
            classes={{
              root: classes.loginBtn,
              disabled: classes.loginBtnDisabled,
            }}
            disabled={!formik.values.email || !formik.values.password}
          >
            log in
          </BaseBtn>
        </CardActions>
      </form>
    </BaseCard>
  );
};

export default LoginForm;
