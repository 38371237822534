import React,{useState,useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import FeaturedIcon from '../../assets/images/Featured_icon.png';
import Fav1  from '../../assets/images/admin1.png';
import Icon from '../../assets/images/Icon.png';
import { Button } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createContent } from '../../utils/functions';
import axios from 'axios';
import FormData from 'form-data';
import fs from 'fs';
const URL = process.env.REACT_APP_API_URL;



const useStyles = makeStyles({
    root: {
      width:"100%",
      height:"100vh",
      background:"#77757F",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      boxSizing:"border-box",
      margin:0,
      padding:0
    },
    div1:{
      background:"#fff",
      borderRadius:"12px",
      width:"50%"
    },
    h3:{
      
      fontSize:"18px",
      fontWeight:"600",
      fontFamily:"Inter",
      fontStyle:"normal",
      lineHeight:"18px",
      color:"#1D1929",
      marginBottom:"5px",
      marginTop:"20px",
      marginLeft:"20px"

    },
    p:{
      marginLeft:"20px",
      marginBottom:"10px",
      fontFamily:"Inter",
      fontStyle:"normal",
      fontWeight:"400",
      fontSize:"14px",
      color:"#615E69",
      padding:0

    },
    div2:{
      border:"1px solid #E8E8EA",
      width:"90%",

      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      padding:"10px",
      margin:"10px",
      marginRight:"20px",
    
      borderRadius:"12px",
      flexDirection:"column"



    },
    img:{
      marginTop:"20px",
 

    },
    h2:{
      fontSize:"14px",
      fontFamily:"Inter",
      fontStyle:"normal",
      color:"#615E69",
      lineHeight:"20px",
      marginBottom:"5px"

    },
    button:{
      background:"none",
      border:"none",
      color:"#0030CC",
      fontFamily:"Inter",
      fontWeight:600,
      fontSize:"14px",
      fontFamily:"normal"

    },
    p3:{
      fontSize:"18px",
      fontFamily:"Inter",
      fontStyle:"normal",
      color:"#615E69",
      lineHeight:"20px"
    },
    div3:{
      border:"1px solid #809EFF",
      width:"90%",

      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
      padding:"10px",
      margin:"10px",
      marginRight:"20px",
    
      borderRadius:"12px",
      flexDirection:"row"



    },
    progressDiv:{
      height:"4px",
      width:"70%",
      background:"blue",
      borderRadius:"30px"

    },
    divLats:{
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
      marginTop:"5px"


    },
    h44:{
      fontFamily:"Inter",
      fontStyle:"normal",
      fontSize:"14px",
      lineHeight:"20px",
      color:"#34303E",
      textDecoration:"none",
      marginBottom:"2px"

    },
    ppp:{
      fontFamily:"Inter",
      fontStyle:"normal",
      fontWeight:400,
      fontSize:"14px",
      color:"#615E69"
    },
    imgj:{
      width:"20px",
      height:"20px",
      background:"#003CFF",
      borderRadius:"100%",
      marginTop:"2px"
    },
    flexDiv:{
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
      width:"90%",
      marginLeft:"20px",
      marginRight:"20px",
      marginTop:"10px",
      marginBottom:"10px"

    },
    btnOne:{
      width:"150px",
      fontFamily:"Inter",
      fontStyle:"normal",
      fontWeight:600,
      fontSize:"16px",
      lineHeight:"24px"

    },
    btnTwo:{
      width:"150px",
      fontFamily:"Inter",
      fontStyle:"normal",
      fontWeight:600,
      fontSize:"14px",
      lineHeight:"24px",
      color:"#fff",
      background:"#003CFF",
      padding:"5px"
    },
    fiid:{
      display:"flex",
      alignItems:"left"
    },
    img1:{
      width:"40px",
      height:"40px"

    },
    diy:{
      marginLeft:"10px",
      marginTop:"2px !important"
    },
    circularOne:{
      color:"#003CFF"

    },
    input:{
      display:"none"
    }

 
    
  });

  

 
function UploadBanner() {
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const authToken = localStorage.getItem("ADM_SES");


    const handleClickUpload = () =>{
      document.getElementById("thiIsInput").click();
    }


    const uploadFile = async (product_name) =>{

        var data = new FormData();
        data.append('file', file);
        data.append('tag', product_name);

        var config = {
          method: 'post',
          url: `${URL}media/upload/image`,
          headers: { 
            'Authorization': `Bearer ${authToken}`, 
            
          },
          data : data
        };

       await axios(config)
        .then(function (response) {
          console.log("file upload response",JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log("file upload error",error);
        });

    }

    const handleOnChange = () =>{

       // GET THE FILE INPUT.
    var fi = document.getElementById('thiIsInput');

    // VALIDATE OR CHECK IF ANY FILE IS SELECTED.
    if (fi.files.length > 0) {
            console.log(fi.files);

        // RUN A LOOP TO CHECK EACH SELECTED FILE.
        for (var i = 0; i <= fi.files.length - 1; i++) {
            // fi.files[0] = document.getElementById('image-1')

            document.getElementById("two_stuff").style.display = "none";
            // document.getElementById("one_stuff").style.display = "none"
            


            

            var fname = fi.files.item(i).name; // THE NAME OF THE FILE.
            console.log(fname);
            setFile(fi.files[0])

           document.getElementById("one_stuff").innerText = `Upload ${fname} `

        }
    } else {
        alert('Please select a file.')
    }
    
    }


    const handleUploadButton =  async () =>{
      // if(file !== null){
      //   const res =   await createContent("banner1","contect_1","banner-1").then((k)=>k);
      //   console.log("the response",res)
      // }

      if(file !== null){
          await uploadFile("banner_1")
      }else{
        console.log("make sure to include file")
      }

    }
  return (
    <div className={classes.root}>
      <div  className={classes.div1}>
        <h3 className={classes.h3}>Upload Banner</h3>
        <p className={classes.p}>Upload and attach files to this project</p>
        <div className={classes.div2}>
          <input className={classes.input} type="file" id="thiIsInput" onChange={handleOnChange} accept="image/*"></input>
          <img className={classes.img} onClick={handleClickUpload} src={FeaturedIcon} alt= "Featured Icon"></img>
          <p id="two_stuff" className={classes.h2}><button onClick={handleClickUpload} className={classes.button}>Click to Upload</button> or drag and drop .</p>

          <p id='one_stuff' className={classes.p3}>1440x380px PNG, JPG or GIF (max. 300KB)</p>

          <div id="empty_div"></div>

        </div>

{/* 
        <div className={classes.div3}>
          <div className={classes.fiid}>
            <img className={classes.img1} src={Fav1} alt=""></img>
            <div className={classes.diy}>
            <h4 className={classes.h44}>Free shopping Banner.jpg</h4>
            <p className={classes.ppp}>200 KB</p>
            <div className={classes.divLats}><div className={classes.progressDiv}></div><span>100 %</span></div>
          </div>
          </div>
          
          <div>
            <CheckCircleIcon className={classes.circularOne}></CheckCircleIcon>
          </div>

        </div> */}


        <div className={classes.flexDiv}>
          
          <Button className={classes.btnOne} variant="outlined" >Cancel</Button>

          <Button onClick={handleUploadButton} className={classes.btnTwo} variant="contained" >Upload Banner</Button>

        </div>
      </div>
    </div>
  )
}

export default UploadBanner