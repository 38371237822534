import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ScheduleIcon from '@material-ui/icons/Schedule';
import OrdersTable from '../../components/orders/OrdersTable';
import BaseStatCard from '../../components/BaseStatCard';
import { fetchDashboardWidgets } from '../../store/auth/authActions';

const mapState = ({ auth }) => ({
  widgetsLoading: auth.widgetsLoading,
  widgets: auth.widgets,
  widgetsErr: auth.widgetsErr,
});
const OrdersHome = () => {
  const dispatch = useDispatch();
  const { widgetsLoading, widgets, widgetsErr } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchDashboardWidgets());
    return () => {};
  }, []);

  return (
    <div>
      {' '}
      <Box mb={3}>
        {widgets && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <BaseStatCard
                icon={<ScheduleIcon />}
                text1="Pending Orders"
                text2={widgets.pendingOrders}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseStatCard
                icon={<ScheduleIcon />}
                text1="Orders Shipping"
                text2="0"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseStatCard
                icon={<ScheduleIcon />}
                text1="Delivered Orders"
                text2="0"
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box>
        <OrdersTable />
      </Box>
    </div>
  );
};

export default OrdersHome;
