import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const BaseChipGreen = withStyles({
  root: {
    backgroundColor: '#E5F7E7',
    color: '#00B517',
    width: '100%',
  },
})(Chip);

export default BaseChipGreen;
