import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ScheduleIcon from '@material-ui/icons/Schedule';

import { users } from '../../dummy';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseChipGrey from '../../components/BaseChipGrey';
import BaseChipRed from '../../components/BaseChipRed';
import BaseChipGreen from '../../components/BaseChipGreen';
import TableSearchField from '../../components/TableSearchField';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import OrdersTable from '../../components/orders/OrdersTable';
import BaseStatCard from '../../components/BaseStatCard';

import { tbStyles } from '../../components/_tbStyles';
import { StyledHead } from '../../components/StyledHead';
import { fetchDashboardWidgets } from '../../store/auth/authActions';

const SERVER_URL = process.env.REACT_APP_API_URL;

// const AntTabs = withStyles({
//   root: {
//     borderBottom: '1px solid #e8e8e8',
//   },
//   indicator: {
//     backgroundColor: '#1890ff',
//   },
// })(Tabs);

// const AntTab = withStyles((theme) => ({
//   root: {
//     textTransform: 'none',
//     minWidth: 72,
//     fontWeight: theme.typography.fontWeightRegular,
//     marginRight: theme.spacing(4),
//     fontFamily: [
//       '-apple-system',
//       'BlinkMacSystemFont',
//       '"Segoe UI"',
//       'Roboto',
//       '"Helvetica Neue"',
//       'Arial',
//       'sans-serif',
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(','),
//     '&:hover': {
//       color: '#1C1C1C',
//       fontWeight: 'bold',
//       opacity: 1,
//     },
//     '&$selected': {
//       color: '#1C1C1C',
//       fontWeight: 'bold',
//     },
//     '&:focus': {
//       color: '#1C1C1C',
//       fontWeight: 'bold',
//     },
//   },
//   selected: {},
// }))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles({
  tbSurface: {
    padding: '24px',
  },
});

const mapState = ({ auth }) => ({
  loading: auth.widgetsLoading,
  data: auth.widgets,
  err: auth.widgetsErr,
});
const DashboardHome = () => {
  const tbClasses = tbStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data, err } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchDashboardWidgets());
    return () => {};
  }, []);

  return (
    <>
      <Box mb={3}>
        <h1 className="f-20 ">Dashboard</h1>
      </Box>

      <Box mb={3}>
        {data && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <BaseStatCard
                icon={<ScheduleIcon />}
                text1="GMV"
                text2={data.grossMarketValue}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseStatCard
                icon={<ScheduleIcon />}
                text1="Total Revenue"
                text2={data.revenue}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseStatCard
                icon={<ScheduleIcon />}
                text1="Successful Purchases"
                text2="coming soon"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseStatCard
                icon={<ScheduleIcon />}
                text1="Daily Active Users"
                text2="coming soon"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseStatCard
                icon={<ScheduleIcon />}
                text1="New Sellers"
                text2={data.newSellers}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseStatCard
                icon={<ScheduleIcon />}
                text1="Total Users"
                text2={data.totalUsers}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <Paper className={classes.tbSurface}>
        <OrdersTable />
      </Paper>
    </>
  );
};

export default DashboardHome;
