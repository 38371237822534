import React from 'react'
import { Box,Paper } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';
import MobileImage from '../../assets/images/beta_mob_banner.jpg';
import WebImage from '../../assets/images/beta_web_banner.jpg';
import {Button} from '@material-ui/core';
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles({
    flexStyle: {
      display:"flex",
      alignItems:"center",
      justifyContent:"space-betwen"
    },
    mainWidth:{
        width:"65%",
        margin:"5px"

    },
    childWidth:{
        width:"30%",
        margin:"5px"

    },
    webImageWidth:{
        width:"100%",
        hight:"100px",
        padding:"10px"
    },
    mobileImageWidth:{
        width:"100%",
        hight:"100px",
        padding:"10px"
     
    },
    flexBoxVeritical:{
        display:"flex",
       
        flexDirection:"column"

    },
    flexBoxVeriticalWithMargin:{
        display:"flex",
       
        flexDirection:"column",
        marginTop:"10px"

    },
    btnSize:{
        width:"40%",
        size:"100px",
        fontSize:"14px",
        marginBottom:"10px",
        marginLeft:"10px",
        fontStyle:"Bold",
   
        background:"#003CFF",
        color:"#fff"
    },
    btnSizeMobile:{
        width:"200px",
        size:"100px",
        fontSize:"14px",
        marginBottom:"10px",
        marginLeft:"10px",
        fontStyle:"Bold",
   
        background:"#003CFF",
        color:"#fff"
    },
    marginTop:{
        marginTop:"10px"
    }
  });

function ContextManagement() {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleNavigate = ()=>{
        console.log("handle navigation")
        navigate("/upload_banner")

    }  
    return (
    <div className={classes.flexBoxVeritical}>
        <Box>
            <h1>Content  Management</h1>
            <Box className={classes.flexStyle}>
                <h3 className={classes.mainWidth}>Current Desktop Banner - Primary</h3>
                <h3 className={classes.childWidth}>Current Mobile Banner - Primary</h3>
            </Box>
            <Box className={classes.flexStyle}>
                <Paper className={classes.mainWidth}>

                    <div className={classes.flexBoxVeritical}>
                    <img className={classes.webImageWidth} src={WebImage}  alt="web-primary"></img>

                    <Button className={classes.btnSize} variant="contained">Upload Banner</Button>
                    </div>

           
                </Paper>

                <Paper className={classes.childWidth}>

                <div className={classes.flexBoxVeritical}>
                    <img className={classes.mobileImageWidth} src={MobileImage}  alt="mobile-primary"></img>

                    <Button className={classes.btnSizeMobile} variant="contained">Upload Banner</Button>
                    </div>

                </Paper>
            </Box>

        </Box>

     
        <Box className={classes.marginTop}>
           
            <Box className={classes.flexStyle}>
                <h3 className={classes.mainWidth}>Current Desktop Banner - Secondary</h3>
                <h3 className={classes.childWidth}>Current Mobile Banner - Secondary</h3>
            </Box>
            <Box className={classes.flexStyle}>
                <Paper className={classes.mainWidth}>

                    <div className={classes.flexBoxVeritical}>
                    <img className={classes.webImageWidth} src={WebImage}  alt="web-primary"></img>

                    <Button onClick={handleNavigate} className={classes.btnSize} variant="contained">Upload Banner</Button>
                    </div>

           
                </Paper>

                <Paper className={classes.childWidth}>

                <div className={classes.flexBoxVeritical}>
                    <img className={classes.mobileImageWidth} src={MobileImage}  alt="mobile-primary"></img>

                    <Button className={classes.btnSizeMobile} variant="contained">Upload Banner</Button>
                    </div>

                </Paper>
            </Box>

        </Box>


    </div>
  )
}

export default ContextManagement