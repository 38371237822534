import {
  ASIGN_RIDER_FAIL,
  ASIGN_RIDER_START,
  ASIGN_RIDER_SUCCESS,
  FETCH_RIDERS_FAIL,
  FETCH_RIDERS_START,
  FETCH_RIDERS_SUCCESS,
} from './riderTypes.js';

const initialState = {
  loadingRiders: false,
  riders: null,
  ridersErr: null,
};

const riderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASIGN_RIDER_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_RIDERS_START: {
      return {
        ...state,
        loadingRiders: true,
      };
    }
    case FETCH_RIDERS_SUCCESS: {
      return {
        ...state,
        loadingRiders: false,
        riders: action.payload,
      };
    }
    case FETCH_RIDERS_FAIL: {
      return {
        ...state,
        loadingRiders: false,
        ridersErr: action.payload,
      };
    }

    default:
      return state;
  }
};

export default riderReducer;
