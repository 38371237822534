import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import BaseTab from '../BaseTab';
import BaseTabs from '../BaseTabs';
import BaseTable from '../BaseTable';
import TableSearchField from '../TableSearchField';
import BaseBtnOutlined from '../BaseBtnOutlined';
import BaseBtnFilled from '../BaseBtnFilled';
import BaseChipGrey from '../../components/BaseChipGrey';
import BaseChipRed from '../../components/BaseChipRed';
import BaseChipGreen from '../../components/BaseChipGreen';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { tbStyles } from '../_tbStyles';

const URL = process.env.REACT_APP_API_URL;

const mapState = ({ auth }) => ({
  authToken: auth.token,
});
const UsersTable = () => {
  const tbClasses = tbStyles();
  const { authToken } = useSelector(mapState);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [widgets, setWidgets] = useState(null);
  useEffect(() => {
    const fetchUsers = async () => {
      const results = await axios.get(`${URL}/users/?page=1&pageSize=1000`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(results.data.users);
    };

    fetchUsers();
    return () => {};
  }, []);


  const viewDetails = (item) =>{
    console.log(item)
  }

  const setStatus = (val) => {
    let status = val.toLowerCase();
    if (status === 'active') {
      return <BaseChipGreen label={status} />;
    }
    if (status === 'inactive') {
      return <BaseChipGrey label={status} />;
    }
    if (status === 'banned') {
      return <BaseChipRed label={status} />;
    }
  };

  return (
    <BaseTable
      ariaLabel="users list table"
      header={
        <>
          <TableCell className={tbClasses.header}>Username</TableCell>
          <TableCell className={tbClasses.header}>Phone Number</TableCell>
          <TableCell className={tbClasses.header}>Email Address</TableCell>
          <TableCell className={tbClasses.header}>Date Joined</TableCell>
          <TableCell className={tbClasses.header}>User Status</TableCell>
          <TableCell className={tbClasses.header}>Store Status</TableCell>
          <TableCell className={tbClasses.header}></TableCell>
          {/* <TableCell className={tbClasses.header}></TableCell> */}
        </>
      }
      body={
        <>
          {data && data.length === 0 && (
            <TableRow>
              <TableCell colSpan={7} className={tbClasses.empty}>
                No data available
              </TableCell>
            </TableRow>
          )}

          {data &&
            data.map((item) => (
              //                 email: "elija@gmail.com"
              // id: 19



              // image: "https://res.cloudinary.com/kapslab/image/upload/lukhu-staging/product/p2KhIP8xdHihq5Rw.jpg.jpg"
              // insertedAt: "2021-11-24T10:43:28.000Z"
              // phoneNumber: "+254743770216"
              // storeStatus: "Active"
              // username: "Elijah"
              <TableRow key={item.id}>
                <TableCell>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={item.image} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.username}
                      secondary={`User ID: #${item.id}`}
                    />
                  </ListItem>
                </TableCell>
                <TableCell>{item.phoneNumber}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell className={tbClasses.noWrap}>
                  {format(new Date(item.insertedAt), 'dd-MM-yyyy')}
                </TableCell>
                <TableCell>{setStatus(item.userStatus)}</TableCell>
                <TableCell>{setStatus(item.storeStatus)}</TableCell>
                <TableCell>  <BaseBtnFilled fullWidth onClick={() => viewDetails(item.id)}>
                    View Details
                  </BaseBtnFilled>
</TableCell>

                {/* <TableCell>
                  <BaseBtnFilled
                    fullWidth
                    // onClick={() => viewDetails(item.id)}
                  >
                    View Details
                  </BaseBtnFilled>
                </TableCell> */}
              </TableRow>
            ))}
        </>
      }
    />
  );
};

export default UsersTable;
