import { makeStyles } from '@material-ui/core/styles';

export const tbStyles = makeStyles({
  table: {
    //   minWidth: 650,
    width: '100%',
  },
  empty: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 'bold',
    fontFamily:"Lato",
    color:"#222222",
    opacity:1,
    fontSize:"14px"
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  voucherCode:{

    textDecoration:"none",
    fontFamily:"Lato",
    fontWeight:"Regular",
    fontSize:"16px",
    color:"#545454",
    letterSpacing:"-13",
    lineHeight:"22px",
    background:"#F4F4F4",
    opacity:1,
    borderRadius:"4px",
    padding:4
  
        
  },
  tableR:{
        
        margin:3,
        alignItems:"center"
  },
  active:{
    textAlign:"center",
    fontFamily:"Lato",
    letterSpacing:"-0.1px",
    color:"#00B517",
    opacity:1,
    fontSize:"16px",
    background:"#E5F7E7",
    borderRadius:"30px",
    padding:8

  },
  inactive:{
    textAlign:"center",
    fontFamily:"Lato",
    letterSpacing:"-0.1px",
    color:"#545454",
    opacity:1,
    fontSize:"16px",
    background:"#EFEFEF",
    borderRadius:"30px",
    padding:8
  },
  scheduled:{
    textAlign:"center",
    fontFamily:"Lato",
    letterSpacing:"-0.1px",
    color:"#FF9017",
    opacity:1,
    fontSize:"16px",
    background:"#FFF3E7",
    borderRadius:"30px",
    padding:8
  },
  btn:{
    textDecoration:"none",
    fontFamily:"Lato",
    fontWeight:"Regular",
    fontSize:"16px",
    color:"#000",
    letterSpacing:"-13",
    lineHeight:"22px",
    
    opacity:1,
  },
  name:{
    fontFamily:"Lato",
    fontWeight:"500",
    fontSize:"16px",
    color:"#222222",
    letterSpacing:"-13",
    lineHeight:"22px",
  },
  allProducts:{
    textDecoration:"none",
    fontFamily:"Lato",
    fontWeight:"Regular",
    fontSize:"16px",
    color:"#545454",
    letterSpacing:"-13",
    lineHeight:"22px",
    opacity:1,
  

  },
  normal:{
    
      width:"100%",
  
      borderRadius:"32px",
      background:"#491DFF",
      color:"#fff",
      fontSize:"10px",
      fontStyle:"normal",
      "&:hover":{
        background:"#491DFF",
      color:"#fff",

      }
    
  }
});
