import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const BaseBtnFilled = withStyles({
  root: {
    borderRadius: '30px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    backgroundColor: '#491DFF',
    color: '#FFF',
    maxWidth: '100%',
    '&:hover': {
      backgroundColor: '#491DFF',
      color: '#FFF',
    },
  },
})(Button);

export default BaseBtnFilled;
