import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import BaseTab from '../BaseTab';
import BaseTabs from '../BaseTabs';
import BaseTable from '../BaseTable';
import TableSearchField from '../TableSearchField';
import BaseBtnOutlined from '../BaseBtnOutlined';
import BaseBtnFilled from '../BaseBtnFilled';
import ChangeOrderStatus from './ChangeOrderStatus';
import { Pagination } from '@mui/material';

import { tbStyles } from '../_tbStyles';

const SERVER = process.env.REACT_APP_API_URL;

const OrdersTable = () => {
  const tbClasses = tbStyles();
  let navigate = useNavigate();

  const [orderStatus, setOrderStatus] = useState('all');
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0); // 0: all 1:pending 2:shipped  3:delivered 4:cancelled
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [pageCount,setPageCount] = useState(3);

  const hanldleClickItem = async (e) =>{
    console.log("starte pagination",e.target.textContent)
    const noholder = e.target.textContent;
    const results2 = await axios.get(
      `${SERVER}/orders?status=${orderStatus}&page=${noholder}&pageSize=20`
    );

    
    setData(results2.data.orders);
  }

  useEffect(() => {
    const fetchOrders = async () => {
      

      const results2 = await axios.get(
        `${SERVER}/orders?status=${orderStatus}&page=1&pageSize=20`
      );

      const results = await axios.get(
        `${SERVER}/orders?status=${orderStatus}`
      );

      const dj = results.data.orders.length / 20

      console.log("the dj is",dj)
      setPageCount(Math.round(dj))
      
      setData(results2.data.orders);
      // console.log('ORDERS', results);
    };

    fetchOrders();
    return () => {};
  }, [orderStatus]);
  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
  };
  const handleOpenStatusDialog = () => {
    setOpenStatusDialog(true);
  };

  const handleChangeTab = async (event, newValue) => {
    setValue(newValue);
    // console.log(newValue);
    if (newValue === 0) {
      setOrderStatus('all');
      const results = await axios.get(
        `${SERVER}/orders?status=${orderStatus}`
      );

      const dj = results.data.orders.length / 20

      console.log("the dj is",dj)
      setPageCount(Math.round(dj))
    }
    if (newValue === 1) {
      setOrderStatus('pending');
      const results = await axios.get(
        `${SERVER}/orders?status=${orderStatus}`
      );

      const dj = results.data.orders.length / 20

      console.log("the dj is",dj)
      setPageCount(Math.round(dj))
    }
    if (newValue === 2) {
      setOrderStatus('shipped');

      const results = await axios.get(
        `${SERVER}/orders?status=${orderStatus}`
      );

      const dj = results.data.orders.length / 20

      console.log("the dj is",dj)
      setPageCount(Math.round(dj))
    }
    if (newValue === 3) {
      setOrderStatus('delivered');

      const results = await axios.get(
        `${SERVER}/orders?status=${orderStatus}`
      );

      const dj = results.data.orders.length / 20

      console.log("the dj is",dj)
      setPageCount(Math.round(dj))
    }
    if (newValue === 4) {
      setOrderStatus('cancelled');

      const results = await axios.get(
        `${SERVER}/orders?status=${orderStatus}`
      );

      const dj = results.data.orders.length / 20

      console.log("the dj is",dj)
      setPageCount(Math.round(dj))
    }
  };

  const handleChangeStatus = (orderId, orderStatus) => {};
  const viewDetails = (orderId) => {
    navigate(`/orders/${orderId}`);
  };
  return (
    <>
      <Box
        mb={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <BaseTabs
          value={value}
          onChange={handleChangeTab}
          aria-label="orders filters"
        >
          <BaseTab label="All Orders" />
          <BaseTab label="Pending" />
          <BaseTab label="Shipping" />
          <BaseTab label="Delivered" />
          <BaseTab label="Cancelled" />
        </BaseTabs>
        {/* <div>
          <TableSearchField placeholder="Search by Order Number" />
        </div> */}
        {/* <div>
          <BaseBtnOutlined>Export Report </BaseBtnOutlined>
        </div> */}
      </Box>
      <BaseTable
        ariaLabel="customers list table"
        handleClickItem={hanldleClickItem}
        page_count = {pageCount}
        header={
          <>
            <TableCell className={tbClasses.header}>Order No.</TableCell>
            <TableCell className={tbClasses.header}>Name</TableCell>
            <TableCell className={tbClasses.header}>Date</TableCell>
            <TableCell className={tbClasses.header}>Payment Method</TableCell>
            <TableCell className={tbClasses.header}>Total</TableCell>
            <TableCell className={tbClasses.header}>Status</TableCell>
            <TableCell className={tbClasses.header}>Details</TableCell>
          </>
        }
        body={
          <>
            {data && data.length === 0 && (
              <TableRow>
                <TableCell colSpan={7} className={tbClasses.empty}>
                  No data available
                </TableCell>
              </TableRow>
            )}

            {data.map((item, i) => (
              <TableRow key={i}>
                <TableCell>#{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {format(new Date(item.date), 'dd-MM-yyyy HH:mm:ss')}
                </TableCell>
                <TableCell>{item.paymentMethod}</TableCell>
                <TableCell>{item.total} </TableCell>
                <TableCell>
                  {/* <Box display="flex" alignItems="center">
                    {item.status}{' '}
                    <Tooltip title="Change status" aria-label="change status">
                      <IconButton
                        color="secondary"
                        aria-label="change status"
                        onClick={handleOpenStatusDialog}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box> */}
                  {item.status}
                </TableCell>
                <TableCell>
                  <BaseBtnFilled fullWidth onClick={() => viewDetails(item.id)}>
                    View Details
                  </BaseBtnFilled>
                </TableCell>
              </TableRow>
            ))}

            
            <ChangeOrderStatus
              open={openStatusDialog}
              handleClose={handleCloseStatusDialog}
            />

         
          </>
        }
      />
    </>
  );
};

export default OrdersTable;
