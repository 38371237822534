import React from 'react';
import Table from '@material-ui/core/Table';

import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { Pagination } from '@mui/material';

import { StyledHead } from './StyledHead';
import { tbStyles } from './_tbStyles';
const BaseTable = ({ ariaLabel, header, body, ordersData,page_count,handleClickItem }) => {
  const tbClasses = tbStyles();


  const handlePaginationShift = (e) =>{

    console.log("starte pagination",e.target.textContent)

  }
  return (
    <TableContainer>
      <Table className={tbClasses.table} aria-label={ariaLabel}>
        <TableHead>
          <StyledHead>{header}</StyledHead>
        </TableHead>
        <TableBody>{body}</TableBody>
      </Table>
     {
      typeof(page_count) !== 'undefined' || page_count !== null ? (
        <Pagination sx ={{
          marginTop:"10px"
        }} count={page_count} shape="rounded" 

        onClick={handleClickItem}/>
        
      ) : ""
     }
      
    </TableContainer>
  );
};

export default BaseTable;
