import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import BaseTable from '../BaseTable';
import TableSearchField from '../TableSearchField';
import BaseBtnOutlined from '../BaseBtnOutlined';
import BaseBtnFilled from '../BaseBtnFilled';
import { tbStyles } from '../_tbStyles';

import { fetchRiders } from '../../store/rider/riderActions';

const mapState = ({ rider }) => ({
  loading: rider.loadingRiders,
  data: rider.riders,
  err: rider.ridersErr,
});
const RidersTable = () => {
  const tbClasses = tbStyles();
  const dispatch = useDispatch();
  const { loading, data } = useSelector(mapState);
  useEffect(() => {
    dispatch(fetchRiders());
    return () => {};
  }, []);

  return (
    <>
      <BaseTable
        ariaLabel="customers list table"
        header={
          <>
            <TableCell className={tbClasses.header}>ID .</TableCell>
            <TableCell className={tbClasses.header}>Name</TableCell>
            <TableCell className={tbClasses.header}>Phone Number</TableCell>
            <TableCell className={tbClasses.header}>Status</TableCell>
          </>
        }
        body={
          <>
            {data && data.length === 0 && (
              <TableRow>
                <TableCell colSpan={7} className={tbClasses.empty}>
                  No data available
                </TableCell>
              </TableRow>
            )}

            {data &&
              data.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>#{item.id}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.phoneNumber}</TableCell>
                  <TableCell>
                    {item.isAvailable ? 'Available' : 'Not Available'}
                  </TableCell>

                  {/* <TableCell>
                  <BaseBtnFilled fullWidth onClick={() => viewDetails(item.id)}>
                    View Details
                  </BaseBtnFilled>
                </TableCell> */}
                </TableRow>
              ))}
          </>
        }
      />
    </>
  );
};

export default RidersTable;
