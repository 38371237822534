import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import BaseStatCard from '../../components/BaseStatCard';
import TransactionsTable from '../../components/transactions/TransactionsTable';
import { fetchDashboardWidgets } from '../../store/auth/authActions';
import { Typography,Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Paragraph from '../../components/Paragraph';
import BaseStatCardVoucher from '../../components/BaseStatCardVoucher';
import Transaction1 from '../../assets/images/transaction_1.png';
import Transaction2 from '../../assets/images/transaction_2.png';
import Transaction3 from '../../assets/images/transaction_3.png';

const mapState = ({ auth }) => ({
  widgetsLoading: auth.widgetsLoading,
  widgets: auth.widgets,
  widgetsErr: auth.widgetsErr,
});

const useStyles = makeStyles({
  word:{
    fontSize:"15px",
    font:"normal normal 600 15px/22px Montserrat",
    letterSpacing:"-0.1px",
    color:"#1C1C1C",
    opacity:"1",
    padding:"10px"
  },
  word1:{
    fontSize:"14px",
    font:"normal normal 600 15px/22px Montserrat",
    letterSpacing:"-0.1px",
    color:"#0C7218",
    opacity:"1",
    paddingLeft:"20px",
    paddingRight:"20px",
    paddingTop:"5px",
    paddingBottom:"5px"
 
  },
  word2:{
    fontSize:"16px",
    font:"normal normal bold 14px/22px Lato",
    letterSpacing:"-0.1px",
    color:"#1C1C1C",
    opacity:"1",
    paddingTop:"10px",
    paddingLeft:"10px",
    paddingRight:"10px",
    marginRight:"10px",
    marginTop:"3px"
  },
  word22:{
    fontSize:"16px",
    font:"normal normal bold 18px/25px Lato",
    letterSpacing:"-0.1px",
    color:"#1C1C1C",
    opacity:"1",
   
    paddingLeft:"10px",
    paddingRight:"10px",
    marginRight:"10px",
    marginTop:"2px"
  },
  box:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  boxl:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    marginTop:"10px"
  },
  box1:{
    display:"flex",

  
    flexDirection:"column"
  },
  boxInner:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    background:" #E5F7E7 0% 0% no-repeat padding-box;",
    borderRadius:"30px",
    margin:"10px"

  },
  wordP:{
    fontSize:"12px",
    font:"normal normal 600 15px/22px Montserrat",
    letterSpacing:"-0.1px",
    color:"#989898",
    opacity:"1",
   
    paddingLeft:"10px",
    paddingRight:"10px",
    marginRight:"10px",

    marginTop:"0px",
    marginBottom:"2px"
  },
  wordPP:{
    fontSize:"12px",
    textDecoration:"underline",
    font:"normal normal 600 15px/22px Montserrat",
    letterSpacing:"-0.1px",
    color:"#4923FF",
    opacity:"1",
   
    paddingLeft:"10px",
    paddingRight:"10px",
    marginRight:"10px",

    marginTop:"0px",
    marginBottom:"15px"
  },
  wordTitle:{
    fontSize:"13px",
    font:"normal normal 600 15px/22px Montserrat",
    letterSpacing:"-0.1px",
  
    opacity:"1",
    paddingLeft:"10px",
    marginTop:"0px",

    marginBottom:"2px",
  },
  wordTitleL:{
    fontSize:"16px",
    font:"Lato",
    letterSpacing:"-0.1px",
  
    color:"#495057",
    opacity:"1",
    paddingLeft:"10px",
    marginTop:"0px",

    marginBottom:"2px",
  },

  view:{
    border:"1px solid #E4E4E4",
    width:"100%",
    marginTop:"5px"
  },
  bow:{
    width:"70%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-round",
    marginTop:"2px",
   
  },
  printR:{
    width:"90%",
    marginTop:"10px",
    color:"#fff",
    background:"#4800FB",
  
    borderRadius:"30px",
    marginLeft:"10px",
    marginRight:"20px",
    fontSize:"14px",
    marginBottom:"10px",
    fontVariantCaps:"normal",
    textTransform:"none",
    font: "normal normal bold 14px/17px Lato",
    "&:hover":{
       cursor:"pointer",
       color:"#fff",
       background:"#4800FB",
     

    }

  },
  ytq:{
    top:"110px",
    left:"318px",
    width:"128px",
    height:"24px",
    textAlign:"left",
    font:"normal normal 600 20px/32px Montserrat",
    letterSpacing:"-0.33px",
    color:"#1C1C1C",
    opacity:"1",
    marginBottom:"30px",
    marginLeft:"20px"
  }
});
const Transactions = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { widgetsLoading, widgets, widgetsErr } = useSelector(mapState);
  useEffect(() => {
    dispatch(fetchDashboardWidgets());
    return () => {};
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid container spacing={2}>
        <Grid container item md={6} xs={12} spacing={2}>

        <Typography className={classes.ytq} variant='h4'>Transactions</Typography>
        </Grid>

      

        <Grid container item md={6} xs={12} spacing={2}></Grid>
        </Grid>
        {widgets && (
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={4}>
              <BaseStatCardVoucher
              
              icon={Transaction1}
              text1="GMV"
              text2={"Ksh 13,2000"}
              up={true}
              percentage={"20 %"}
              />
             
            </Grid>
            <Grid item xs={12} md={4}>
            <BaseStatCardVoucher
              
              icon={Transaction2}
              text1="Total Revenuw"
              text2={"Ksh 13,2000"}
              up={true}
              percentage={"20 %"}
              />
              {/* <BaseStatCard
                // icon={<ScheduleIcon />}
                text1="Total Revenue"
                text2={widgets.revenue}
              /> */}
            </Grid>
            <Grid item xs={12} md={4}>
            <BaseStatCardVoucher
              
              icon={Transaction3}
              text1="Pending Transactions"
              text2={"Ksh 13,2000"}
              up={false}
              percentage={"20 %"}
              />
              {/* <BaseStatCard
                // icon={<ScheduleIcon />}
                text1="Pending Transactions"
                text2="coming soon"
              /> */}
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={9}>
            <TransactionsTable />
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper>
              <Box className={classes.box}>
                <Typography className={classes.word} variant='h6' >Details </Typography>
                <Box className={classes.boxInner}>
                <Typography className={classes.word1} variant='h6' >Payment </Typography>
                </Box>

              </Box>

              <div className={classes.view}></div>

              <Box className={classes.box}>
           
              <Typography className={classes.word2} variant='h6' >Date</Typography>
              <Typography className={classes.word2} variant='h6' >Transaction ID </Typography>
               
              </Box>

              <Box className={classes.box}>
                <p className={classes.wordP}>Jan </p>
                <p className={classes.wordP}>2023</p>

              </Box>

              <Typography className={classes.word2} variant='h6' >User Details</Typography>

              <Box className={classes.bow}>
                <p className={classes.wordTitle}>Full Name :</p>
                <p className={classes.wordP}>Devon James</p>
              </Box>

              <Box className={classes.bow}>
                <p className={classes.wordTitle}>Username :</p>
                <p className={classes.wordP}>Devon James</p>
              </Box>

              <Typography className={classes.word2} variant='h6' >Phone Number</Typography>
              <p className={classes.wordP}>+254 712 345 678</p>

              <Typography className={classes.word2} variant='h6' >Email Address</Typography>
              <p className={classes.wordP}>dev.jamie@abc.com</p>

              <Typography className={classes.word2} variant='h6' >Item Purchased</Typography>
              <p className={classes.wordPP}>Off-White Carravaggio T-shirt…</p>

              <div className={classes.view}></div>

              <Box className={classes.boxl}>

                <p className={classes.wordTitleL}>Amount</p>

                <Typography className={classes.word22} variant='h6' >KSH 187,000</Typography>


              </Box>

              <div className={classes.view}></div>

              <Button className={classes.printR}>Print Receipt</Button>

            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Transactions;
