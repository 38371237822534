import React from 'react';
import { Outlet } from 'react-router-dom';

const Users = () => {
  return (
    <section>
      <Outlet />
    </section>
  );
};

export default Users;
