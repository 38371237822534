import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const BaseChipRed = withStyles({
  root: {
    backgroundColor: '#FEEAEA',
    color: '#FA3434',
    width: '100%',
  },
})(Chip);

export default BaseChipRed;
