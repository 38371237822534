import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { DateRangePicker} from '@mui/x-date-pickers-pro';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import { Box,IconButton,Typography,TextField } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import CloseIcon   from '@material-ui/icons/Close';
import qs from 'qs';
import { LIVE_URL } from '../../api';
import { Alert } from '@mui/material';
import {MenuItem} from '@material-ui/core';

const SERVER = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 200,
  },
  actions: {
    justifyContent: 'space-between',
  },
  btn: {
    width: '144px',
  },
  flexTitle:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  h2t:{
    fontWeight:800,
    fontFamily:"Lato",
    fontSize:"16px",
    

  },
  closebt:{
    background:"#666",
    padding:8,
    color:"#fff",
    fontWeight:800,
    fontFamily:"Lato",
    fontSize:"16px",
    borderRadius:"100%",
    "&:hover":{
      background:"#666",
      color:"#fff"

    }


  },
  dialogb:{
    display:"flex",
    alignItems:"center",
    flexDirection:"column"
  },
  div1:{
    marginTop:10,
    width:"100%",
    border:"1px solid #f6f6f6"
  },
  h22t:{
   
    width:"100%",
    fontWeight:800,
    fontFamily:"Lato",
    fontSize:"16px",
    color:"#5a37ff"
    

  },
  textF:{
    width:"100%",
    padding:8,
    background:"#f2f2fe",
    color:"#333",
    fontSize:"14px",
    border:"none",
    marginLeft:3
  },
  orderc:{
    width:"!00%",
    marginTop:10,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  innerHtm:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  innerHtmm:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    margin:2,
    padding:2,
    background:"#f2f2fe"
  },
  textFf:{
    marginTop:10,
    width:"100%",
    padding:8,
    background:"#f2f2fe",
    color:"#333",
    fontSize:"14px",
    border:"none",
    marginLeft:3
  },
  btnss:{
    marginTop:20,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  bty:{
    width:"100%",
    margin:10
  },
  kui:{
    margin:5,
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  }

}));

const VoucherSuccessDialog = ({ handleClose,open, orderId }) => {
  const sessionAdmin =  localStorage.getItem("ADM_SES")
  const classes = useStyles();



  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      classes={{
        paper: classes.root,
      }}
    >
      <DialogTitle id="simple-dialog-title" ><Box className={classes.flexTitle}>
      <Typography variant="h6" component="h2" className={classes.h2t}>
        You have successfully created a voucher code
      </Typography>
      

        
        
        </Box>
       
        </DialogTitle>
      
      <DialogContent sx={{
        width:"100%"
      }}>

  
      <p>Users can now use their voucher codes to get offers on items in different categories. Kindly reach out to the Marketing team for guidance on how to share the vouchers.</p>

      <BaseBtnFilled onClick={handleClose}>View Vouchers</BaseBtnFilled>
        
      </DialogContent>
    
    </Dialog>
  );
};

VoucherSuccessDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
};

export default VoucherSuccessDialog;
