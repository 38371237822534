import React from 'react';
import RidersTable from '../../components/riders/RidersTable';

const RidersHome = () => {
  return (
    <div>
      <h3>Riders</h3>
      <RidersTable />
    </div>
  );
};

export default RidersHome;
