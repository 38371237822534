import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import BaseBtnFilled from '../BaseBtnFilled';

const useStyles = makeStyles({
  root: {
    marginBottom: '16px',
    border: '1px solid #F5F6F7',
    boxShadow: '0px 3px 6px #00000012',
    borderRadius: '6px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
const RiderInfoCard = ({ name, id, phone, handleSelect }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.content}>
        <Box>
          <div>
            <p>
              Driver Name : <span className="txt-bold"> {name} </span>
            </p>
            <p>
              Driver ID : <span className="txt-bold">{id}</span>
            </p>
            <p>
              Phone Number : <span className="txt-bold">{phone}</span>
            </p>
          </div>
        </Box>
        <Box>
          <BaseBtnFilled onClick={handleSelect}>Select Rider</BaseBtnFilled>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RiderInfoCard;
