import React, { useState } from 'react';
import { Link, Outlet, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';

import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import StorefrontIcon from '@material-ui/icons/Storefront';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import DashboardIcon from '@material-ui/icons/Dashboard';
import logo from '../../assets/images/logo.svg';
import { logout } from '../../store/auth/authActions';
import Icon1 from '../../assets/images/icon2_con.svg';
// import dashIcon from '../'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#FFFFFF',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: 'black',
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  notIcon: {},
  nested: {
    paddingLeft: theme.spacing(4),
  },
  iconv:{
    width:"30px",
    fill:"#AAAAAA"
  }
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: '#4a4062',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: '#4a4062',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '30px',
    },
  },
}))(MenuItem);

const mapState = ({ auth }) => ({
  token: auth.token,
});

export default function Layout() {
  const classes = useStyles();
  const { token } = useSelector(mapState);

  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const isProfileMenuOpen = Boolean(profileAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [openOrders, setOpenOrders] = useState(false);

  const handleClickOpenOrders = () => {
    setOpenOrders(!openOrders);
  };
  const handleSignOut = () => {
    dispatch(logout());
  };
  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="my-account-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <StyledMenuItem onClick={handleSignOut}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </StyledMenuItem>
    </Menu>
  );
  if (!token) {
    return <Navigate to="/auth/login" replace />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="black"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>

          <div>
            <IconButton style={{ paddingRight: 0 }}>
              <Avatar />
            </IconButton>

            <IconButton style={{ padding: 0 }} onClick={handleProfileMenuOpen}>
              <KeyboardArrowDownIcon style={{ color: '#000000' }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <Toolbar>
          <Box>
            <img src={logo} width="100%" alt="Lukhu Port Logo" />
          </Box>
        </Toolbar>
        {/* <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div> */}
        <Divider />
        <List>
          <ListItem button component={Link} to="">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            onClick={handleClickOpenOrders}
            component={Link}
            to="orders"
          >
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
            {openOrders ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openOrders} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                disabled
                button
                className={classes.nested}
                component={Link}
                to="orders/pending"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Pending Orders" />
              </ListItem>
              <ListItem
                disabled
                button
                className={classes.nested}
                component={Link}
                to="orders/shipping"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Orders Shipping" />
              </ListItem>
              <ListItem
                disabled
                button
                className={classes.nested}
                component={Link}
                to="orders/delivered"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary=" Delivered Orders" />
              </ListItem>
              <ListItem
                disabled
                button
                className={classes.nested}
                component={Link}
                to="orders/cancelled"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Cancelled Orders" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button component={Link} to="transactions">
            <ListItemIcon>
              <LocalAtmIcon />
            </ListItemIcon>
            <ListItemText primary="Transactions" />
          </ListItem>
          <ListItem button component={Link} to="users">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem button disabled>
            <ListItemIcon>
              <StorefrontIcon />
            </ListItemIcon>
            <ListItemText primary="Stores" />
          </ListItem>
          <ListItem button disabled>
            <ListItemIcon>
              <ShoppingBasketIcon />
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItem>
          <ListItem button component={Link} to="riders">
            <ListItemIcon>
              <MotorcycleIcon />
            </ListItemIcon>
            <ListItemText primary="Riders" />
          </ListItem>

          {/* start vouchers */}
          <ListItem button component={Link} to="voucher">
            <ListItemIcon>
              <img className={classes.iconv} src={Icon1} alt={"vouchers"} />
            </ListItemIcon>
            <ListItemText primary="Vouchers" />
          </ListItem>


          <ListItem button component={Link} to="content_management">
            <ListItemIcon>
              <img className={classes.iconv} src={Icon1} alt={"content-management"} />
            </ListItemIcon>
            <ListItemText primary="Content Management" />
          </ListItem>

          {/* end vouchers */}
          <ListItem button disabled>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Outlet />
        </Container>
      </main>

      {renderProfileMenu}
    </div>
  );
}
