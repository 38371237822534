import {
  FETCH_ORDER_FAIL,
  FETCH_ORDER_START,
  FETCH_ORDER_SUCCESS,
} from './orderTypes';

const initialState = {
  singleOrderLoading: false,
  singleOrder: null,
  singleOrderErr: null,
  orderRider: null,
  orderDeliveryDetails: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_START: {
      return {
        ...state,
        singleOrderLoading: true,
      };
    }
    case FETCH_ORDER_SUCCESS: {
      return {
        ...state,
        singleOrderLoading: false,
        singleOrder: action.payload.order,
        orderRider: action.payload.rider,
        items:action.payload.items,
        orderDeliveryDetails: action.payload.delDetails,
      };
    }
    case FETCH_ORDER_FAIL: {
      return {
        ...state,
        singleOrderLoading: false,
        singleOrderErr: action.payload,
      };
    }

    default:
      return state;
  }
};

export default orderReducer;
