import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import riderReducer from './rider/riderReducer';
import orderReducer from './order/orderReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  rider: riderReducer,
  order: orderReducer,
  
});

export default rootReducer;
