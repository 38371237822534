import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core';


const useStyles = makeStyles({

    paragraph1:{
      marginLeft:"10px",
      color:"#989898"
    },
    paragraph2:{
      marginRight:"10px",
      color:"#989898"
    }
  });


function Paragraph({left,right,margin,text}) {
    const classes = useStyles();
    if(left ===  true){
        console.log("running left ")
        return(
            <Typography sx={
                {
                    marginLeft:margin,
                    color:"#989898",
                    fontSize:"16px"
                }
            }>{text}</Typography>
        )
    }else if(right === true){
        console.log("running right")
        return(
            <Typography className={
                {
                    marginRight:margin,
                    color:"#989898",
                    fontSize:"16px"
                }
            }>{text}</Typography>
        )  
    }else{
        return(
            <Typography className={
                {
                    marginRight:margin,
                    marginLeft:margin,
                    color:"#989898",
                    fontSize:"16px"
                }
            }>{text}</Typography>
        )  
    }


  
}

export default Paragraph