import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { DateRangePicker} from '@mui/x-date-pickers-pro';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import { Box,IconButton,Typography,TextField } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import CloseIcon   from '@material-ui/icons/Close';
import qs from 'qs';
import { LIVE_URL } from '../../api';
import { Alert } from '@mui/material';
import {MenuItem} from '@material-ui/core';
import { IN_DEVELOPMENT } from '../../api';
import { DEVELOPMENT_URL } from '../../api';
// import { SERVER_URL } from '../../api';

const SERVER_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 200,
  },
  actions: {
    justifyContent: 'space-between',
  },
  btn: {
    width: '144px',
  },
  flexTitle:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  h2t:{
    fontWeight:800,
    fontFamily:"Lato",
    fontSize:"16px",
    

  },
  closebt:{
    background:"#666",
    padding:8,
    color:"#fff",
    fontWeight:800,
    fontFamily:"Lato",
    fontSize:"16px",
    borderRadius:"100%",
    "&:hover":{
      background:"#666",
      color:"#fff"

    }


  },
  dialogb:{
    display:"flex",
    alignItems:"center",
    flexDirection:"column"
  },
  div1:{
    marginTop:10,
    width:"100%",
    border:"1px solid #f6f6f6"
  },
  h22t:{
   
    width:"100%",
    fontWeight:800,
    fontFamily:"Lato",
    fontSize:"16px",
    color:"#5a37ff"
    

  },
  textF:{
    width:"100%",
    padding:8,
    background:"#f2f2fe",
    color:"#333",
    fontSize:"14px",
    border:"none",
    marginLeft:3
  },
  orderc:{
    width:"!00%",
    marginTop:10,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  innerHtm:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  innerHtmm:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    margin:2,
    padding:2,
    background:"#f2f2fe"
  },
  textFf:{
    marginTop:10,
    width:"100%",
    padding:8,
    background:"#f2f2fe",
    color:"#333",
    fontSize:"14px",
    border:"none",
    marginLeft:3
  },
  btnss:{
    marginTop:20,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  bty:{
    width:"100%",
    margin:10
  },
  kui:{
    margin:5,
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  }

}));

const VoucherSuccessDialogActivated = ({id,status, handleClose,open, orderId,welcomeOffer,voucherCode,maximumOrder,voucherAmount ,maximumVoucherAmount,startDate,endDate}) => {
  const sessionAdmin =  localStorage.getItem("ADM_SES")
  const classes = useStyles();

  const goVoucher = async () =>{
    console.log("voucher code",voucherCode)
    const devurl = IN_DEVELOPMENT ? `${DEVELOPMENT_URL}/api/voucher/update` : `${SERVER_URL}/vouchers/${id}`;
var data = qs.stringify({

'name': welcomeOffer,
'code': `${voucherCode}`,
'maxOrders': `${maximumOrder}`,
'amount': `${voucherAmount}`,
'minOrderAmount': `${maximumVoucherAmount}`,
'status': status,
'startDate': startDate,
'endDate': endDate 
});
var config = {
method: 'put',
url: devurl,
headers: { 
 'Authorization': `Bearer ${sessionAdmin}`, 
 'Content-Type': 'application/x-www-form-urlencoded'
},
data : data
};

await axios(config)
.then(function (response) {
console.log(JSON.stringify(response.data));
const res =  response.data;
if(res.status === "success"){
 
 return window.location.reload();
}
})
.catch(function (error) {
console.log(error);
});

   }


const handleConfirm = () =>{
  goVoucher();
}
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      classes={{
        paper: classes.root,
      }}
    >
      <DialogTitle id="simple-dialog-title" ><Box className={classes.flexTitle}>
      <Typography variant="h6" component="h2" className={classes.h2t}>
        Voucher Activation
      </Typography>
      

        
        
        </Box>
       
        </DialogTitle>
      
      <DialogContent sx={{
        width:"100%"
      }}>

  
      <p>Are you sure you want to activate this voucher? Customers will now be able to use this voucher once activated.</p>

      <BaseBtnFilled onClick={handleConfirm}>Confirm</BaseBtnFilled>
        
      </DialogContent>
    
    </Dialog>
  );
};

VoucherSuccessDialogActivated.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  status:PropTypes.string.isRequired,
  welcomeOffer:PropTypes.string.isRequired,
  voucherCode:PropTypes.string.isRequired,
  maximumOrder:PropTypes.string.isRequired,
  voucherAmount:PropTypes.string.isRequired,
  maximumVoucherAmount:PropTypes.string.isRequired,
  startDate:PropTypes.string.isRequired,
  endDate:PropTypes.string.isRequired,
  id:PropTypes.string.isRequired,
};

export default VoucherSuccessDialogActivated;
