import axios from 'axios';
import 'dotenv/config';
import {
  FETCH_ORDER_FAIL,
  FETCH_ORDER_START,
  FETCH_ORDER_SUCCESS,
} from './orderTypes';

const URL = process.env.REACT_APP_API_URL;

export const fetchOrder = (id) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;

    dispatch({
      type: FETCH_ORDER_START,
    });

    axios
      .get(`${URL}/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        console.log("item stuff one", res.data)
        const order = res.data.orders;
        const items = res.data.items;
        const rider = res.data.riderDetails[0];
        const delDetails = res.data.deliveryDetails;
        // console.log('order', order);
        // console.log('rider', rider);
        // console.log('delivery', delDetails);

        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: {
            order,
            rider,
            items,
            delDetails,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ORDER_FAIL,
          payload: error,
        });
      });
  };
};
