import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const TableSearchField = withStyles((theme) => ({
  input: {
    borderRadius: '20px',
    border: '0.25px solid #707070',
    fontSize: '0.875rem',
    padding: '10px 12px',

    // Use the system font instead of the default Roboto font.
    fontFamily: ['Lato', 'sans-serif'].join(','),
    '&:focus': {
      boxShadow: 'none',
    },
  },
}))(InputBase);

export default TableSearchField;
