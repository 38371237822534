import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const BaseChipGrey = withStyles({
  root: {
    backgroundColor: '#EFEFEF',
    color: '#545454',
    width: '100%',
  },
})(Chip);

export default BaseChipGrey;
