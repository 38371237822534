
// import '../../api/miragemock';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AppsIcon from '@material-ui/icons/Apps';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EventIcon from '@material-ui/icons/Event';
import { Button,Popover,Typography } from '@material-ui/core';
// import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import { users } from '../../dummy';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseChipGrey from '../../components/BaseChipGrey';
import BaseChipRed from '../../components/BaseChipRed';
import BaseChipGreen from '../../components/BaseChipGreen';
import TableSearchField from '../../components/TableSearchField';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import OrdersTable from '../../components/orders/OrdersTable';
import BaseStatCard from '../../components/BaseStatCard';

import { tbStyles } from '../../components/_tbStyles';
import { StyledHead } from '../../components/StyledHead';
import { fetchDashboardWidgets } from '../../store/auth/authActions';
import BaseTable from '../../components/BaseTable';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Icon1 from '../../assets/images/icon1.svg';
import Icon2 from '../../assets/images/Icon2.svg';
import Icon3 from '../../assets/images/Icon3.svg';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { LIVE_URL } from '../../api';
import { IN_DEVELOPMENT,DEVELOPMENT_URL } from '../../api';
import CreateVoucherDalog from './CreateVoucherDalog';
import BaseStatCardVoucher from '../../components/BaseStatCardVoucher';
import DeactivateVoucherDialog from './DeactivateVoucherDialog';
import ActivateVoucherDialog from './ActivateVoucherDialog';
// import { SERVER_URL } from '../../api';

// import IconButton from '@mui/material/IconButton';

// if(IN_DEVELOPMENT){
const SERVER_URL = process.env.REACT_APP_API_URL;

// }


const useStyles = makeStyles({
    tbSurface: {
      padding: '24px',
    },
    voucher:{
      width:"100%",
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
      background:"#f7f7f7"
    },
    ll:{
      margin:10,
      width:"100%",
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
      background:"#fff"
    },
    iconss:{
      
      marginLeft:"5px",
      color:"#333",
      fontSize:"16px",
      fontWeight:500,
      fontFamily:"Lato"
    },
    icons:{
      
      marginRight:"5px",
      color:"#333",
      fontSize:"16px",
      fontWeight:500,
      fontFamily:"Lato"
    },
    text:{
      margin:1,
      color:"#000",
      fontSize:"14px",
      fontWeight:500,
      fontFamily:"Lato",
      fontStyle:"bold"

    },
    report:{
      width:"100%",
      margin:5,
      borderRadius:"32px",
      border:"1px solid #491dff",
      color:"#491dff",
      fontSize:"14px",
      fontStyle:"normal",
      "&:hover":{
        background:"#fff"
      }


    },
    addvoucher:{
      width:"100%",
      margin:5,
      borderRadius:"32px",
      background:"#491DFF",
      color:"#fff",
      fontSize:"14px",
      fontStyle:"normal",
      "&:hover":{
        background:"#491DFF",
      color:"#fff",

      }
    },
    selectb:{
      color:"#000",
      border:"none"
    }
  });

const VoucherHome = () => {
    const sessionAdmin =  localStorage.getItem("ADM_SES")
    const [allVouchers, setallVouchers] = useState([])
    const[openVoucher,setOpenVoucher] = useState(false);

    const[totalRedeptionAmount,setTotalRedemptionAmount] = useState("");
    const[totalRedemptionPercentge,setTotalRedemptionPercentge] = useState("");
    const[redRose,setRose] = useState(false);
    
    const[newRedeptionAmount,setNewRedemptionAmount] = useState("");
    const[newRedemptionPercentge,setNewRedemptionPercentge] = useState("");
    const[ref,setref] = useState(false)

    const[totalRedeption,setTotalRedemption] = useState("");
    const[totalRedemptionpp,setTotalRedemptionpp] = useState("");
    const[rr,setrr] = useState(false);

    const[openProper,setOpenProper] = useState(false);
    const[openl,setOpenl] = useState("");
    const[voucherkey,setVoucherKey] = useState("");

    const [openDeactivate,closeDeactivate] = useState(false);
    const[activateVoucherMessage,setactivateVoucherMessaage] = useState("");
  const[activateBody,setACtivateBody]=useState("");

  const[openActivateDialog,setOpenActivateDialog] = useState(false);



   

    const classes = useStyles();
    const tbClasses = tbStyles();

    const closeA = () =>{
      setOpenActivateDialog(false)
    }

    const handProper = ()=>{
      setOpenProper(true)
    }

    const handleChange=(e) =>{
      console.log("kayd",e.target.value)
      const val =  e.target.value;

      setOpenl(val.status)
      setVoucherKey(val.id)
      setOpenl(val.status)
      setVoucherKey(val.id)
      if(val.status.toLowerCase() === "deactivate"){
        closeDeactivate(true);

    setactivateVoucherMessaage("Voucher Deactivation")
    setACtivateBody("Are you sure u want to Deactivate Voucher? Customers will not be able to use this voucher once deactivated")

      }else if(val.status.toLowerCase() === "activate"){
        setOpenActivateDialog(true);
      }

    }

    const handleClose = () =>{
      setOpenProper(false);
    }

   

    const setVoucherFnc = () =>{
      setOpenVoucher(true)
    }

    
    const dashBoardMetrics = async () =>{
      
    

      var config = {
        method: 'get',
        url: IN_DEVELOPMENT ? `${DEVELOPMENT_URL}/api/metrics` : `${SERVER_URL}/vouchers/metrics`,
        headers: {
          'Authorization': `Bearer ${sessionAdmin}`
         }
      };
  
      axios(config)
      .then(function (response) {
        const kk = JSON.parse(JSON.stringify(response.data))
        console.log("jji",kk);
        const alld = IN_DEVELOPMENT ? kk.metrics : kk;
        if(alld.status === "success"){
          const totalRedemption = alld.data.totalRedemptions;
          if(totalRedemption.status === "decreased"){
            setRose(false)
          }else{
            setRose(true)
          }
          setTotalRedemptionPercentge(totalRedemption.percentage);
          setTotalRedemptionAmount(totalRedemption.amount)


          const newRedeptionss = alld.data.newRedemptions;
          if(newRedeptionss.status === "increased"){
            setref(true)
          }else{
            setref(false);
          }
          setNewRedemptionPercentge(newRedeptionss.percentage);
          setNewRedemptionAmount(newRedeptionss.amount)

          const redm = alld.data.redemptionAmount;
          if(redm.status === "dropped"){
            setrr(false)
          }else{
            setrr(true)
          }
          setTotalRedemptionpp(redm.percentage)
          setTotalRedemption(redm.amount)

        }

        
       
      })
      .catch(function (error) {
        console.log(error);
      });
      }


    const handleCloseDeactivate = () =>{
      closeDeactivate(false);
    }
    

    const getALlVouchers = async (page,pageSize) =>{
      
    

    var config = {
      method: 'get',
      url: IN_DEVELOPMENT ? `${DEVELOPMENT_URL}/api/vouchers` : `${SERVER_URL}/vouchers?page=${page}&pageSize=${pageSize}`,
      headers: {
        'Authorization': `Bearer ${sessionAdmin}`
       }
    };

    axios(config)
    .then(function (response) {
      const alld = JSON.parse(JSON.stringify(response.data))
      console.log("jji",alld);
      const kk = IN_DEVELOPMENT ? alld.vouchers : alld.vouches;
      const empty_list = []
      for(const d in kk){
        const item = kk[d];

        const dd =  (
         
         <TableRow   className={tbClasses.tableR}>
        <TableCell><p className={tbClasses.name}>{item.name}</p></TableCell>
        <TableCell ><a className={tbClasses.voucherCode}>{item.code}</a></TableCell>
        <TableCell ><a className={item.status.toLowerCase() === "active" ? tbClasses.active : item.status.toLowerCase() === "inactive" ? tbClasses.inactive : tbClasses.scheduled  }>{item.status}</a></TableCell>
        <TableCell><p className={tbClasses.allProducts}>{item.products}</p></TableCell>
        <TableCell><p className={tbClasses.allProducts}>{item.amount}</p> </TableCell>
        <TableCell><p className={tbClasses.allProducts}>{item.redemptions}</p> </TableCell>
        <TableCell ><FormControl variant="standard"  disableUnderline fullWidth>
        <InputLabel   id="demo-simple-select-label">...</InputLabel>
        <Select
        className={classes.btn}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={openl}
          label="Age"
          onChange={handleChange}
        >
          {item.status.toLowerCase() === "active" ? (<MenuItem value={{
            id:item.id,
            status:"deactivate"
          }}>Deactivate</MenuItem>) : item.status.toLowerCase() === "inactive" ? (<MenuItem value={{
            id:item.id,
            status:"activate"
          }}>Activate</MenuItem>) : (<MenuItem value={{
            id:item.id,
            status:"deactivate"
          }}>Deactivate</MenuItem>)  }

          
         
        </Select>
      </FormControl></TableCell>
        </TableRow>
        )
        empty_list.push(dd)
        

      }
      setallVouchers(empty_list)
    })
    .catch(function (error) {
      console.log(error);
    });
    }

    useEffect(() => {
     getALlVouchers(1,10)
     dashBoardMetrics()
    }, [])
    const closeDialog = () =>{
      setOpenVoucher(false)

    }

  return (
    <>
     <Box className={classes.voucher} mb={3}>
        <h1 className="f-20 ">Vouchers</h1>

        <Box className={classes.ll}>
          <AppsIcon sx={{
            marginLeft:"2px"
          }} className={classes.iconss}/>
          <h2 className={classes.text}>Filter Users </h2>
          <ArrowDropDownIcon className={classes.icons}/>

        </Box>

        <Box className={classes.ll}>
          <EventIcon  className={classes.iconss}/>
          <h2 className={classes.text}>21 May 2022- 10 Jun 2022</h2>
          <ArrowDropDownIcon className={classes.icons}/>



        </Box>

        <Button className={classes.report} variant="outlined">Export Report</Button>
        <Button onClick={setVoucherFnc}  className={classes.addvoucher} variant="contained">Add Voucher</Button>

       
        {/* <IconButton>
          <ExpandCircleDownIcon/>
        </IconButton> */}

        
        
      </Box>

      <Box mb={3}>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <BaseStatCardVoucher
              icon={Icon1}
              text1="Total Redemption"
              text2={totalRedeptionAmount}
              up={redRose}
              percentage={totalRedemptionPercentge}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <BaseStatCardVoucher
              icon={Icon2}
              text1="New Redemptions"
              text2={newRedeptionAmount}
              up={ref}
              percentage={newRedemptionPercentge}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <BaseStatCardVoucher
              icon={Icon3}
              text1="Redemption Amount"
              text2={totalRedeption}
              up={rr}
              percentage={totalRedemptionpp}
            />
          </Grid>
         
         
       
        </Grid>
      
    </Box>

    {/* table */}
    <BaseTable  
         ariaLabel="voucher list table"
        header={
          <>
            <TableCell className={tbClasses.header}>Name</TableCell>
            <TableCell className={tbClasses.header}>Code</TableCell>
            <TableCell className={tbClasses.header}>Status</TableCell>
            <TableCell className={tbClasses.header}>Products</TableCell>
            <TableCell className={tbClasses.header}>Amount</TableCell>
            <TableCell className={tbClasses.header}>Redemption</TableCell>
            <TableCell className={tbClasses.header}>Action</TableCell>
          </>
        }
        
        body={
          <>
          {allVouchers}
          
         
          </>
        }>
         </BaseTable>

    <CreateVoucherDalog open={openVoucher} handleClose={closeDialog} ></CreateVoucherDalog>
    <ActivateVoucherDialog id={voucherkey} statuss={openl} open={openActivateDialog} handleClose={closeA} />
    <DeactivateVoucherDialog voucherTitle={activateVoucherMessage} voucherBody={activateBody} id={voucherkey} status={openl} open={openDeactivate} handleClose={handleCloseDeactivate} />
    </>
  )
}

export default VoucherHome