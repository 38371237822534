import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import ChangeOrderStatus from '../../components/orders/ChangeOrderStatus';
import AssignRider from '../../components/riders/AssignRider';
import { fetchRiders } from '../../store/rider/riderActions';
import { fetchOrder } from '../../store/order/orderActions';

const SERVER = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    // width: '700px',
  },
  pCard: {
    display: 'flex',
    marginTop: '16px',
    boxShadow: 'none',
  },
  pMedia: {
    width: '148px',
    height: '148px',
  },
  pContent: {
    paddingBottom: 0,
    paddingTop: 0,
    width: 'calc(100% - 160px)',
  },
  infoCard: {
    padding: '16px',
    width: '244px',
  },
}));

const mapState = ({ order }) => ({
  loading: order.singleOrderLoading,
  orderDetails: order.singleOrder,
  orderErr: order.singleOrderErr,
  orderRider: order.orderRider,
  items:order.items,
  orderDeliveryDetails: order.orderDeliveryDetails,
});

const OrdersDetailPage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    loading,
    orderDetails,
    orderErr,
    orderRider,
    items,
    orderDeliveryDetails,
  } = useSelector(mapState);
  {
    /* Seller Location, Buyer Location, Buyer Mtaani, Seller Mtaani */
  }

  const [sellerMtaani, setsellerMtaani] = useState(null);
  const [sellerLocation, setsellerLocation] = useState(null);
  const [buyerMtaani, setbuyerMtaani] = useState(null);
  const [buyerLocation, setbuyerLocation] = useState(null);
  const [oderd,setOrderd] = useState(null)
  useEffect(() => {
    dispatch(fetchOrder(id));
    return () => {};
  }, [id]);




  console.log("order details stuff ",orderDetails)
  console.log("items stuff ", items)
  useEffect(() => {
    if (orderDeliveryDetails) {
      const sMtaani = orderDeliveryDetails.find(
        (item) => item.type === 'Seller Mtaani'
      );
      const sLocation = orderDeliveryDetails.find(
        (item) => item.type === 'Seller Location'
      );
      const bLocation = orderDeliveryDetails.find(
        (item) => item.type === 'Buyer Location'
      );
      const bMtaani = orderDeliveryDetails.find(
        (item) => item.type === 'Buyer Mtaani'
      );

      setsellerMtaani(sMtaani);
      setsellerLocation(sLocation);
      setbuyerLocation(bLocation);
      setbuyerMtaani(bMtaani);
    }
    return () => {};
  }, [orderDeliveryDetails]);

  const [openStatusDialog, setOpenStatusDialog] = useState(false);

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
    dispatch(fetchOrder(id));
  };
  const handleOpenStatusDialog = () => {
    setOpenStatusDialog(true);
  };

  const [openRiderDialog, setopenRiderDialog] = useState(false);
  const handleCloseRiderDialog = () => {
    setopenRiderDialog(false);
  };

  const handleOpenRiderDialog = () => {
    dispatch(fetchRiders());
    setopenRiderDialog(true);
  };
  return (
    <>
      {/* {orderDetails && (
        <Paper className={classes.root}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <p>#{id}</p>
              <p className="txt-bold">{orderDetails.name}</p>
            </Box>
            <Box>
              <p>
                Order Date: {format(new Date(orderDetails.date), 'dd.MM.yyyy')}
              </p>
              <Box>
                <BaseBtnFilled onClick={handleOpenRiderDialog}>
                  Assign Rider
                </BaseBtnFilled>
              </Box>
            </Box>
          </Box>
          <Box my={2}>
            {' '}
            <BaseBtnOutlined onClick={handleOpenStatusDialog}>
              Change order status
            </BaseBtnOutlined>
          </Box>
          <Divider />
          <Card className={classes.pCard}>
            <CardMedia
              className={classes.pMedia}
              image={orderDetails.itemImage}
              title="order item"
            />

            <CardContent className={classes.pContent}>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <p className="txt-gray2">
                    Item ID:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.orderNumber}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Item Name:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.itemName}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Size:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.size}
                    </span>
                  </p>{' '}
                  <p className="txt-gray2">
                    Item Price:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.itemPrice}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Condition:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.condition}
                    </span>
                  </p>
                </Box>
                <Box>
                  <p className="txt-gray2">
                    Buyer:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.buyerName}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Buyer Phone:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.buyerPhoneNumber}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Order total:{' '}
                    <span className="txt-black txt-bold">
                      KSh {orderDetails.orderTotal}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Payment Method:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.paymentMethod}
                    </span>
                  </p>
                  <Chip label={orderDetails.orderStatus} />
                </Box>
                <Box>
                  <p className="txt-gray2">
                    Shop Name:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.storeName}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Shop Owner:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.storeOwner}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Shop Contact:{' '}
                    <span className="txt-black txt-bold">
                      {orderDetails.storePhoneNumber}
                    </span>
                  </p>
                </Box>
              </Box>
              <Box>
                <p className="txt-black txt-bold"> Delivery details</p>

                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Card className={classes.infoCard}>
                      <p className="txt-black f-12 txt-bold">
                        Buyer Delivery Details
                      </p>
                      {buyerLocation && buyerLocation.location && (
                        <p className="txt-gray2">
                          Delivery address:{' '}
                          <span className="txt-black txt-bold">
                            {buyerLocation.location}
                          </span>
                        </p>
                      )}

                      {buyerMtaani && buyerMtaani.location && (
                        <p className="txt-gray2">
                          Pickup Store:{' '}
                          <span className="txt-black txt-bold">
                            {buyerMtaani.location}
                          </span>
                        </p>
                      )}
                      {buyerLocation && (
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {buyerLocation.contact}
                          </span>
                        </p>
                      )}
                      {buyerMtaani && (
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {buyerMtaani.contact}
                          </span>
                        </p>
                      )}
                    </Card>
                  </Box>
                  <Box>
                    <Card className={classes.infoCard}>
                      <p className="txt-black f-12 txt-bold">
                        Seller Collection Details
                      </p>
                      {sellerLocation && (
                        <p className="txt-gray2">
                          Store address:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {sellerLocation.location}
                          </span>
                        </p>
                      )}
                      {sellerLocation && (
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {sellerLocation.contact}
                          </span>
                        </p>
                      )}

                      {sellerMtaani && (
                        <p className="txt-gray2">
                          Dropoff:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {sellerMtaani.location}
                          </span>
                        </p>
                      )}
                      {sellerMtaani && (
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {sellerMtaani.contact}
                          </span>
                        </p>
                      )}
                    </Card>
                  </Box>
                  {orderRider && (
                    <Box>
                      <Card className={classes.infoCard}>
                        <p className="txt-black f-12 txt-bold">Rider details</p>
                        <p className="txt-gray2">
                          Name:{' '}
                          <span className="txt-black txt-bold">
                            {orderRider.name}
                          </span>
                        </p>
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {orderRider.contact}
                          </span>
                        </p>
                      </Card>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      )} */}

      {orderDetails && (
        orderDetails.map((ord)=>(
     
            items.map((ki)=>(
                <Paper className={classes.root}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <p>#{id}</p>
              <p className="txt-bold">{ord.name}</p>
            </Box>
            <Box>
              <p>
                Order Date: {format(new Date(ord.date), 'dd.MM.yyyy')}
              </p>
              <Box>
                <BaseBtnFilled onClick={handleOpenRiderDialog}>
                  Assign Rider
                </BaseBtnFilled>
              </Box>
            </Box>
          </Box>
          <Box my={2}>
            {' '}
            <BaseBtnOutlined onClick={handleOpenStatusDialog}>
              Change order status
            </BaseBtnOutlined>
          </Box>
          <Divider />
          <Card className={classes.pCard}>
            <CardMedia
              className={classes.pMedia}
              image={ki.image}
              title="order item"
            />

            <CardContent className={classes.pContent}>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <p className="txt-gray2">
                    Item ID:{' '}
                    <span className="txt-black txt-bold">
                      {ord.orderNumber}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Item Name:{' '}
                    <span className="txt-black txt-bold">
                      {ki.name}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Size:{' '}
                    <span className="txt-black txt-bold">
                      {ki.size}
                    </span>
                  </p>{' '}
                  <p className="txt-gray2">
                    Item Price:{' '}
                    <span className="txt-black txt-bold">
                      {ki.price}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Condition:{' '}
                    <span className="txt-black txt-bold">
                      {ki.condition}
                    </span>
                  </p>
                </Box>
                <Box>
                  <p className="txt-gray2">
                    Buyer:{' '}
                    <span className="txt-black txt-bold">
                      {ord.buyerName}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Buyer Phone:{' '}
                    <span className="txt-black txt-bold">
                      {ord.buyerPhoneNumber}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Order total:{' '}
                    <span className="txt-black txt-bold">
                      KSh {ord.orderTotal}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Payment Method:{' '}
                    <span className="txt-black txt-bold">
                      {ord.paymentMethod}
                    </span>
                  </p>
                  <Chip label={ord.orderStatus} />
                </Box>
                <Box>
                  <p className="txt-gray2">
                    Shop Name:{' '}
                    <span className="txt-black txt-bold">
                      {ord.storeName}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Shop Owner:{' '}
                    <span className="txt-black txt-bold">
                      {ord.storeOwner}
                    </span>
                  </p>
                  <p className="txt-gray2">
                    Shop Contact:{' '}
                    <span className="txt-black txt-bold">
                      {ord.storePhoneNumber}
                    </span>
                  </p>
                </Box>
              </Box>
              <Box>
                <p className="txt-black txt-bold"> Delivery details</p>

                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Card className={classes.infoCard}>
                      <p className="txt-black f-12 txt-bold">
                        Buyer Delivery Details
                      </p>
                      {buyerLocation && buyerLocation.location && (
                        <p className="txt-gray2">
                          Delivery address:{' '}
                          <span className="txt-black txt-bold">
                            {buyerLocation.location}
                          </span>
                        </p>
                      )}

                      {buyerMtaani && buyerMtaani.location && (
                        <p className="txt-gray2">
                          Pickup Store:{' '}
                          <span className="txt-black txt-bold">
                            {buyerMtaani.location}
                          </span>
                        </p>
                      )}
                      {buyerLocation && (
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {buyerLocation.contact}
                          </span>
                        </p>
                      )}
                      {buyerMtaani && (
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {buyerMtaani.contact}
                          </span>
                        </p>
                      )}
                    </Card>
                  </Box>
                  <Box>
                    <Card className={classes.infoCard}>
                      <p className="txt-black f-12 txt-bold">
                        Seller Collection Details
                      </p>
                      {sellerLocation && (
                        <p className="txt-gray2">
                          Store address:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {sellerLocation.location}
                          </span>
                        </p>
                      )}
                      {sellerLocation && (
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {sellerLocation.contact}
                          </span>
                        </p>
                      )}

                      {sellerMtaani && (
                        <p className="txt-gray2">
                          Dropoff:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {sellerMtaani.location}
                          </span>
                        </p>
                      )}
                      {sellerMtaani && (
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {' '}
                            {sellerMtaani.contact}
                          </span>
                        </p>
                      )}
                    </Card>
                  </Box>
                  {orderRider && (
                    <Box>
                      <Card className={classes.infoCard}>
                        <p className="txt-black f-12 txt-bold">Rider details</p>
                        <p className="txt-gray2">
                          Name:{' '}
                          <span className="txt-black txt-bold">
                            {orderRider.name}
                          </span>
                        </p>
                        <p className="txt-gray2">
                          Contact:{' '}
                          <span className="txt-black txt-bold">
                            {orderRider.contact}
                          </span>
                        </p>
                      </Card>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Paper>
            ))

          
           
          
          
        ))
      )}

      <ChangeOrderStatus
        open={openStatusDialog}
        handleClose={handleCloseStatusDialog}
        orderId={id}
      />
      <AssignRider
        open={openRiderDialog}
        handleClose={handleCloseRiderDialog}
        orderId={id}
      />
    </>
  );
};

export default OrdersDetailPage;
