import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const BaseBtnOutlined = withStyles({
  root: {
    borderRadius: '30px',
    textTransform: 'capitalize',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    border: '1px solid #481DFF',
    color: '#481DFF',
    maxWidth: '100%',
    '&:hover': {
      border: '1px solid #481DFF',
      color: '#481DFF',
    },
  },
})(Button);

export default BaseBtnOutlined;
