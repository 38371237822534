import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  root: {
    boxShadow: '0px 3px 6px #00000012',
    borderRadius: '6px',
    padding: '16px',
  },
  primary: {
    color: '#989898',
    fontSize: '0.875rem',
  },
  secondary: {
    color: '#181818',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
});
const BaseStatCard = ({ icon, text1, text2, bgAvatar }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <ListItem>
        <ListItemAvatar className={`${bgAvatar}`}>
          <Avatar>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText
          classes={{ primary: classes.primary, secondary: classes.secondary }}
          primary={text1}
          secondary={text2}
        />
      </ListItem>
    </Paper>
  );
};

export default BaseStatCard;
