import {
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CHECK_LOGGED_IN,
  LOG_OUT,
  FETCH_WIDGETS_START,
  FETCH_WIDGETS_FAIL,
  FETCH_WIDGETS_SUCCESS,
} from './authTypes';

const initialState = {
  loading: false,
  token: localStorage.getItem('ADM_SES'),
  loginErr: null,

  widgetsLoading: false,
  widgets: null,
  widgetsErr: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CHECK_LOGGED_IN:
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.payload.token,
      };
    }
    case LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
        loginErr: action.payload,
      };
    }
    case LOG_OUT: {
      localStorage.removeItem('ADM_SES');
      return {
        ...state,
        token: null,
      };
    }
    case FETCH_WIDGETS_START: {
      return {
        ...state,
        widgetsLoading: true,
      };
    }
    case FETCH_WIDGETS_SUCCESS: {
      return {
        ...state,
        widgetsLoading: false,
        widgets: action.payload,
      };
    }
    case FETCH_WIDGETS_FAIL: {
      return {
        ...state,
        widgetsLoading: false,
        widgetsErr: action.payload,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
