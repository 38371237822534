import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import BaseInput2 from '../../components/BaseInput2';
import BaseCard from '../../components/BaseCard';
import BaseBtn from '../../components/BaseBtn';

// import { signUp } from '../../redux/auth/authActions';

const useStyles = makeStyles({
  card: {
    width: '400px',
  },
  signup: {
    backgroundColor: '#4900ff',
    border: '2px solid #4900ff',
    color: '#FFF',
    marginBottom: '12px',
    textTransform: 'capitalize',
    fontWeight: 'medium',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  signupDisabled: {
    backgroundColor: '#E2E2E2',
    border: '2px solid #E2E2E2',
    color: '#717171',
    marginBottom: '12px',
    textTransform: 'capitalize',
    fontWeight: 'medium',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    '&:hover': {
      backgroundColor: '#E2E2E2',
      color: '#717171',
    },
  },
  errorClass: {
    border: '1px solid red',
    borderRadius: '2px',
  },
  successClass: {
    border: '1px solid green',
    borderRadius: '2px',
  },
  avatar: {
    height: '24px',
    width: '24px',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  selectInput: {
    padding: '0',
    width: '80px',
    color: '#4900ff',
    fontWeight: 'bold',
  },
  flagIcon: {
    width: '16px',
    height: '16px',
  },
});

const validationSchema = yup.object({
  username: yup
    .string()
    .required('Please enter username')
    .min(5, 'Username must be at least 5 characters long'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter email'),
  phoneNumber: yup
    .string()
    .required('Please enter phone number')
    .matches(/^((01)|(07))[0-9]+$/, 'Invalid format! Please try : 07XX...')
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number'),
  password: yup
    .string()
    .required('Please enter password')
    .matches(
      /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,

      'Password  must contain at least 6 characters, one uppercase, one number and one special case character'
    ),
});

// const mapState = ({ auth }) => ({
//   signupError: auth.regErr,
//   isPhoneVerified: auth.isPhoneVerified,
//   isLoggedIn: auth.isLoggedIn,
//   token: auth.token,
//   profile: auth.profile,
// });
const SignupForm = () => {
  const classes = useStyles();
  const server = process.env.REACT_APP_SERVER_URL;
  // const { isLoggedIn, isPhoneVerified, signupError, token, profile } =
  //   useSelector(mapState);

  const [showPassword, setshowPassword] = useState(false);
  const [countryInfo, setcountryInfo] = useState([]);
  // const [loading, setLoading] = useState([]);
  // const [success, setSuccess] = useState(false);

  // const dispatch = useDispatch();
  const [isUsernameAvailable, setisUsernameAvailable] = useState({
    status: '',
    msg: '',
  });

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      phoneNumber: '',
      password: '',
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      const user = {
        username: values.username,
        emailAddress: values.email,
        phoneNumber: values.phoneNumber,
        password: values.password,
      };
      // console.log(user);
      // console.log(server);
      axios
        .post(`${server}/register`, user)
        .then((res) => {
          const result = res.data;
          // console.log(res);
        })
        .catch((err) => {
          // console.log(err);
        });
      // formik.resetForm();
    },
  });

  // toogle disabled property class for signup button
  let btnClass = '';
  if (
    !formik.values.username ||
    !formik.values.email ||
    !formik.values.phoneNumber ||
    !formik.values.password
  ) {
    btnClass = classes.signupDisabled;
  } else {
    btnClass = classes.signup;
  }

  // if (token && profile && isPhoneVerified) {
  //   return <Redirect to="/select-preference" />;
  // }
  // if (token && profile && !isPhoneVerified) {
  //   return <Redirect to="/auth/signup/verify-phone-number" />;
  // }

  return (
    <BaseCard className={classes.card}>
      <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
        <CardContent>
          <h2
            className="txt-ruby h2x-large-bold"
            style={{ marginBottom: '24px' }}
          >
            Sign up
          </h2>
          <p className="txt-violet p-14" style={{ marginBottom: '24px' }}>
            Let&apos;s get to know you better
          </p>

          <div className="mb-16">
            <BaseInput2
              id="username"
              name="username"
              type="text"
              placeholder="username *"
              value={formik.values.username}
              onChange={formik.handleChange}
              fullWidth
            />
            {formik.errors.username && formik.touched.username && (
              <p className="input-error">
                <small>{formik.errors.username}</small>
              </p>
            )}
            {isUsernameAvailable.status === 'true' && (
              <p className="input-success">
                <small>{isUsernameAvailable.msg}</small>
              </p>
            )}
            {isUsernameAvailable.status === 'false' && (
              <p className="input-error">
                <small>{isUsernameAvailable.msg}</small>
              </p>
            )}
            {isUsernameAvailable.status === 'err' && (
              <p className="input-error">
                <small>{isUsernameAvailable.msg}</small>
              </p>
            )}
          </div>
          <div className="mb-16">
            <BaseInput2
              fullWidth
              id="email"
              name="email"
              type="email"
              placeholder="email *"
              value={formik.values.email}
              onChange={formik.handleChange}
              className={
                formik.errors.email && formik.touched.email
                  ? classes.errorClass
                  : ''
              }
            />
            {formik.errors.email && formik.touched.email && (
              <p className="input-error">
                <small>{formik.errors.email}</small>
              </p>
            )}
          </div>
          <div className="mb-16">
            <BaseInput2
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              placeholder="phone number * e.g 07XX..."
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              className={
                formik.errors.phoneNumber && formik.touched.phoneNumber
                  ? classes.errorClass
                  : ''
              }
            />
            {/* {formik.errors.phoneExt && formik.touched.phoneExt && (
              <p className="input-error">
                <small>{formik.errors.phoneExt}</small>
              </p>
            )} */}
            {formik.errors.phoneNumber && formik.touched.phoneNumber && (
              <p className="input-error">
                <small>{formik.errors.phoneNumber}</small>
              </p>
            )}
          </div>

          <div className="mb-16">
            <BaseInput2
              fullWidth
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              placeholder="password *"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon fontSize="small" />
                    ) : (
                      <VisibilityOffIcon fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              value={formik.values.password}
              onChange={formik.handleChange}
              className={
                formik.errors.password && formik.touched.password
                  ? classes.errorClass
                  : ''
              }
            />
            {formik.errors.password && formik.touched.password && (
              <p className="input-error">
                <small>{formik.errors.password}</small>
              </p>
            )}
          </div>
          {/* <div className="txt-center mb-8">
           
          </div> */}
        </CardContent>

        <CardActions>
          <BaseBtn type="submit" fullWidth className={btnClass}>
            Sign Up
          </BaseBtn>
        </CardActions>
      </form>
    </BaseCard>
  );
};

export default SignupForm;
