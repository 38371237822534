import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import BaseCard from '../../components/BaseCard';
import BaseBtn from '../../components/BaseBtn';

const useStyles = makeStyles({
  loginBtn: {
    backgroundColor: '#4900ff',
    color: '#fff',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  loginBtnDisabled: {
    backgroundColor: '#E2E2E2',
    color: '#717171',
  },
});

const Welcome = () => {
  const classes = useStyles();

  return (
    <BaseCard>
      <CardContent>
        <Box mb={6}>
          <h2 className="txt-ruby txt-center h2x-large-bold">Hey, John!</h2>
          <p className="txt-violet txt-lato txt-center f-14">
            It seems you are new here, karibu.{' '}
          </p>
        </Box>
        <Box mb={3}>
          <p className=" txt-lato txt-center f-14">
            Your account has been successfully created on{' '}
            <span className="txt-bold">Lukhu Port</span>.
          </p>
        </Box>
        <Box mb={3}>
          <p className=" txt-lato txt-center f-14">
            <span>
              {' '}
              Email: <span className="txt-bold">john.odindo@lukhu.co.ke</span>
            </span>
            <br />
            <span>
              {' '}
              Temporary Password: <span className="txt-bold"> FksJ30!#</span>
            </span>
          </p>
        </Box>
        <Box mb={3}>
          <p className=" txt-lato txt-center f-14">
            Click the button below to reset your password and complete your Log
            In. The temporary password expires in 1 day.{' '}
          </p>
        </Box>
      </CardContent>

      {/* ACTION PROMPTS NEW PASSWORD SCREEN */}
      <CardActions>
        <BaseBtn
          type="submit"
          fullWidth
          classes={{
            root: classes.loginBtn,
          }}
        >
          Complete Log In
        </BaseBtn>
      </CardActions>
    </BaseCard>
  );
};

export default Welcome;
