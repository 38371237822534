import axios from 'axios';
import 'dotenv/config';
import {
  ASIGN_RIDER_FAIL,
  ASIGN_RIDER_START,
  ASIGN_RIDER_SUCCESS,
  FETCH_RIDERS_FAIL,
  FETCH_RIDERS_START,
  FETCH_RIDERS_SUCCESS,
} from './riderTypes.js';

const URL = process.env.REACT_APP_API_URL;

export const assignRider = (details) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;

    axios
      .put(`${URL}/riders/assign`, details, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        const result = res.data;
        // console.log(result);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
};

export const fetchRiders = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;

    dispatch({
      type: FETCH_RIDERS_START,
    });

    axios
      .get(`${URL}/riders?page=1&pageSize=10`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        //   id: 1
        // insertedAt: "2022-03-22T08:36:10.000Z"
        // isAvailable: false
        // lastUpdate: "2022-03-22T09:20:17.000Z"
        // name: "Antony Taabu Ndege"
        // phoneNumber: "+254793983299"
        const result = res.data.riders;
        // console.log(result);
        dispatch({
          type: FETCH_RIDERS_SUCCESS,
          payload: result,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
};
