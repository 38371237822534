import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import logo from '../assets/images/logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  ctn: {
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bgBar: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 1px 4px #00000014',
  },
}));

const AuthLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" className={classes.bgBar}>
          <Toolbar>
            <Box>
              <img src={logo} width="100%" alt="Lukhu Port Logo" />
            </Box>
          </Toolbar>
        </AppBar>
      </div>

      <Container maxWidth="sm" className={classes.ctn}>
        {children}
      </Container>
    </>
  );
};

export default AuthLayout;
