import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { DateRangePicker} from '@mui/x-date-pickers-pro';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import { Box,IconButton,Typography,TextField } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import CloseIcon   from '@material-ui/icons/Close';
import qs from 'qs';
import { LIVE_URL } from '../../api';
import { Alert } from '@mui/material';
import {MenuItem} from '@material-ui/core';
import VoucherSuccessDialog from './VoucherSuccessDialog';

const SERVER = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 400,
  },
  actions: {
    justifyContent: 'space-between',
  },
  btn: {
    width: '144px',
  },
  flexTitle:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  h2t:{
    fontWeight:800,
    fontFamily:"Lato",
    fontSize:"16px",
    

  },
  closebt:{
    background:"#666",
    padding:8,
    color:"#fff",
    fontWeight:800,
    fontFamily:"Lato",
    fontSize:"16px",
    borderRadius:"100%",
    "&:hover":{
      background:"#666",
      color:"#fff"

    }


  },
  dialogb:{
    display:"flex",
    alignItems:"center",
    flexDirection:"column"
  },
  div1:{
    marginTop:10,
    width:"100%",
    border:"1px solid #f6f6f6"
  },
  h22t:{
   
    width:"100%",
    fontWeight:800,
    fontFamily:"Montserrat",
    fontSize:"16px",
    color:"#1C1C1C",
    opacity:1,
    letterSpacing:"-0.3px"
    

  },
  textF:{
    width:"100%",
    height:"40px",
    fontFamily:"Montserrat",
    background:"#F5F2FE",
    color:"#111111",
    fontSize:"16px",
    border:"1px solid #F5F2FE",
    borderRadius:"5px",
    opacity:1,
    paddingLeft:5
  },
  orderc:{
    width:"!00%",
    marginTop:10,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  innerHtm:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  innerHtmm:{
    width:"60%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    marginRight:3,
    background:"#f2f2fe"
  },
  textFf:{
    
    width:"100%",
  
    background:"#f2f2fe",
    color:"#333",
    fontSize:"14px",
    border:"none",
    marginLeft:3
  },
  btnss:{
    marginTop:20,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  bty:{
    width:"100%",
    margin:10
  },
  kui:{
    margin:5,
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  h22tt:{
    width:"100%",
    fontWeight:800,
    fontFamily:"Montserrat",
    fontSize:"16px",
    color:"#4923FF",
    opacity:1,
    letterSpacing:"-0.3px"
  },
  textFh:{
    width:"100%",
  
    fontFamily:"Montserrat",
    background:"#F5F2FE",
    color:"#111111",
    fontSize:"14px",
  
    opacity:1
  },
  mp2:{
    width:"130%",
    marginLeft:3,
    display:"flex",
    alignItems:"center",
    justifyContent:"center"

  },
  slectb:{
    width:"100%",
    height:"40px",
    
    "& .MuiSelect-root":{
      color:"#111111",
    fontFamily:"Montserrat",
    opacity:1,
    fontSize:"16px",
    padding:8,

    },
    "& .MuiInput-formControl":{
      marginTop:0
    }

  }

}));

const CreateVoucherDalog = ({ open, orderId,handleClose }) => {
  const sessionAdmin =  localStorage.getItem("ADM_SES")
  const classes = useStyles();
  const[clicked,setClicked] = useState(false);
  const[welcomeOffer,setWelcomeOffer] = useState("")
  const[voucherCode,setVoucherCode] = useState("")
  const[maximumOrder,setMaximumOrder] = useState("");
  const[voucherAmount,setVoucherAmount] = useState("");
  const[maximumVoucherAmount,setMaximumVoucherAMount] = useState("")
  const[startDate,setStartDate] = useState("")
  const[endDate,setEndDate] = useState("");
  const[status,setStatus] = useState("active");
  const[successmsg,setSuccessmsg]= useState("");
  const[error,setErrormsg] = useState("");
  const[currency,setCurrency] = useState("KES");
  const [focus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const [focus1, setFocused1] = useState(false);
  const [hasValue1, setHasValue1] = useState(false);


  const handleWelcomeOffer = (e) =>{
    setWelcomeOffer(e.target.value)
  }

  const handleVoucherCode= (e) =>{
    setVoucherCode(e.target.value)
  }


  const handleMaximumOrders = (e) =>{
    setMaximumOrder(e.target.value)
  }

  const setClickedBT = ()=>{
    
    setClicked(true)
  }
  const handleVoucherAMount = (e) =>{
    setVoucherAmount(e.target.value)
  }

  const handleVoucherAMountt = (e) =>{
    setMaximumVoucherAMount(e.target.value)
  }

  const setStartD = (e)=>{
    setStartDate(e.target.value)

  }

  const setEndD = (e) =>{
    setEndDate(e.target.value)
  }

  const setstatusChange = (e) =>{
    setStatus(e.target.value)

  }

  const setCurrencyChange = (e) =>{
    setCurrency(e.target.value)

  }

  const handleClickedClosed = () =>{
    
    // setClicked(false)
    window.location.reload(false);
  }
 
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const onFocus1 = () => setFocused1(true);
  const onBlur1 = () => setFocused1(false);

  const createVoucher = (name,code,maxorders,amount,minimumOrderAMount,status,startdate,enddate)=>{
   
  
    var data = qs.stringify({
      'name': name,
      'code': code,
      'maxOrders': maxorders,
      'amount': amount,
      'minOrderAmount': minimumOrderAMount,
      'status': status,
      'startDate': startdate,
      'endDate': enddate
    });
    var config = {
      method: 'post',
      url: `${SERVER}vouchers`,
      headers: { 
        'Authorization': `Bearer ${sessionAdmin}`
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setClickedBT()
    })
    .catch(function (error) {
      console.log(error);
      setSuccessmsg("")
        setErrormsg(error.message)
    });
      }

    const handleSubmission =  () =>{
      if(welcomeOffer.length > 0 || voucherCode.length > 0 || maximumOrder.length > 0 || voucherAmount.length > 0 || maximumVoucherAmount.length > 0 || startDate.length > 0 || endDate.length > 0 || status.length > 0  ){
        createVoucher(welcomeOffer,voucherCode,maximumOrder,voucherAmount,maximumVoucherAmount,status,startDate,endDate);
        
      }else{
        setSuccessmsg("")
        setErrormsg("Please fill the required details")

      }

    }



  
    

  



  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      classes={{
        paper: classes.root,
      }}
    >
      <DialogTitle id="simple-dialog-title" ><Box className={classes.flexTitle}>
      <Typography variant="h6" component="h2" className={classes.h2t}>
        AddVoucher
      </Typography>
      <IconButton onClick={handleClose} className={classes.closebt}>
        <CloseIcon/>

      </IconButton>

        
        
        </Box>
       
        </DialogTitle>
        <div className={classes.div1}></div>
      <DialogContent sx={{
        width:"100%"
      }}>

        <Box>
          <Typography variant="h6" component="p" className={classes.h22tt}>
          Voucher Name
        </Typography>

      <TextField value={welcomeOffer} onChange={handleWelcomeOffer} className={classes.textF} id="standard-basic" placeholder='Welcome Offer' variant="standard"
      InputProps={{ disableUnderline: true }} />

      <Box className={classes.orderc}>
          <Box>
              <Typography variant="h6" component="p" className={classes.h22tt}>
              Voucher Code
            </Typography>

            <TextField value={voucherCode} onChange={handleVoucherCode} className={classes.textF} id="standard-basic" placeholder='KARIBU10' variant="standard"
            InputProps={{ disableUnderline: true }} />
          </Box>

          <Box>
          <Typography variant="h6" component="p" className={classes.h22tt}>
          Maximum Orders
        </Typography>

        <TextField onChange={handleMaximumOrders} value={maximumOrder} className={classes.textF} id="standard-basic" placeholder='1000' variant="standard"
            InputProps={{ disableUnderline: true }} />
          </Box>

      </Box>

      {/* voucher amount */}

      <Box className={classes.orderc}>
          <Box>
          <Typography variant="h6" component="p" className={classes.h22tt}>
          Voucher Amount
        </Typography>

        <Box>
          <Box className={classes.innerHtm}>
          <Box className={classes.innerHtmm}>
                  <Select
                  
                      className={classes.slectb}
                     
                      value={currency}
                      defaultValue="KES"
                      label="KES"
                      onChange={setCurrencyChange}
                      disableUnderline
                    >
                      <MenuItem value={"kes"}>KES</MenuItem>
                      
                    </Select>

                  </Box>
              

              
            <TextField onChange={handleVoucherAMount} value={voucherAmount} className={classes.textF} id="standard-basic" placeholder='100' variant="standard"
            InputProps={{ disableUnderline: true }} />
          </Box>
        </Box>

       
          </Box>

          <Box>
          <Typography variant="h6" component="p" className={classes.h22tt}>
          Minimum Order Amount
        </Typography>

        <TextField onChange={handleVoucherAMountt} value={maximumVoucherAmount} className={classes.textF} id="standard-basic" placeholder='1000' variant="standard"
            InputProps={{ disableUnderline: true }} />
          </Box>

      </Box>


      {/* status */}
      <Box className={classes.orderc}>
            <Typography variant="h6" component="p" className={classes.h22tt}>
              Status
            </Typography>

      </Box>

      <Box className={classes.kui}>

            <Box className={classes.innerHtmm}>
                <Select
                   className={classes.slectb}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Status"
                    onChange={setstatusChange}
                    disableUnderline
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"scheduled"}>Scheduled</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                  </Select>
   
            </Box>

  <Box className={classes.mp2}>
  <TextField
                  onFocus={onFocus}
                  onBlur={onBlur}
                  id="date"
                  label="Start Date"
                  type={hasValue || focus ? "date" : "text"}
                  onChange={setStartD}
                  value={startDate}
                
                  className={classes.textFh}
                  InputProps={{ disableUnderline: true,
                    }} 
                />

            <TextField
            onFocus={onFocus1}
            onBlur={onBlur1}
                  id="date1"
                  label="End Date"
                  type={hasValue1 || focus1 ? "date" : "text"}
                  onChange={setEndD}
                  value={endDate}
                
                  className={classes.textF}
                  InputProps={{ disableUnderline: true,
                    shrink: true }} 
                 
  />
  </Box>



      </Box>

      {/* end */}
      <Box className={classes.orderc}>
        

       

{error.length > 0 && (<Alert severity="error">{error}</Alert>)}
<VoucherSuccessDialog  open={clicked} handleClose={handleClickedClosed}/>
        


        
          </Box>

          <Box>
        

       
          </Box>

      </Box>



        <Box className={classes.btnss}>
          <BaseBtnFilled onClick={handleSubmission} className={classes.bty}>Submit</BaseBtnFilled>
          <BaseBtnOutlined className={classes.bty} onClick={handleClose}>Cancel</BaseBtnOutlined>
        </Box>
        
      </DialogContent>
    
    </Dialog>
  );
};

CreateVoucherDalog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
};

export default CreateVoucherDalog;
