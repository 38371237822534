import axios from 'axios';
import 'dotenv/config';

import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CHECK_LOGGED_IN,
  LOG_OUT,
  FETCH_WIDGETS_START,
  FETCH_WIDGETS_FAIL,
  FETCH_WIDGETS_SUCCESS,
} from './authTypes';

const url = process.env.REACT_APP_API_URL;

export const loadUser = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      dispatch({
        type: CHECK_LOGGED_IN,
        payload: {
          token,
        },
      });
    } else {
      return null;
    }
  };
};

export const loginUser = (creds) => {
  return (dispatch, getState) => {
    dispatch({
      type: LOGIN_START,
    });
    axios
      .post(`${url}/login`, creds)
      .then((res) => {
        const result = res.data;
        const token = result.token;
        localStorage.setItem('ADM_SES', token);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            token,
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        dispatch({
          type: LOGIN_FAIL,
          payload: error,
        });
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOG_OUT,
    });
  };
};

export const fetchDashboardWidgets = () => {
  const date =  new Date()
  
  return (dispatch) => {
    dispatch({
      type: FETCH_WIDGETS_START,
    });
    axios
      .get(`${url}/dashboard/widgets?startDate=2020-01-01&endDate=${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`)
      .then((res) => {
        const widgets = res.data.widgets;
        dispatch({
          type: FETCH_WIDGETS_SUCCESS,
          payload: widgets,
        });
        // {"message":"retrieved widgets","widgets":{"grossMarketValue":5128,"revenue":228,"activeListings":9,"pendingOrders":25,"dailyVisitors":0,"newSellers":20,"totalUsers":36}}
      })
      .catch((error) => {
        dispatch({
          type: FETCH_WIDGETS_FAIL,
          payload: error,
        });
      });
  };
};
