import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import BaseBtnFilled from '../BaseBtnFilled';
import BaseBtnOutlined from '../BaseBtnOutlined';

const SERVER = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 400,
  },
  actions: {
    justifyContent: 'space-between',
  },
  btn: {
    width: '144px',
  },
}));

const ChangeOrderStatus = ({ handleClose, open, orderId }) => {
  const classes = useStyles();

  const [status, setStatus] = useState('');
  const [success, setsuccess] = useState('');
  const [fail, setfail] = useState('');

  const handleChange = (e) => {
    const newVal = e.target.value;
    console.log(newVal);
    setStatus(newVal);
    console.log(orderId);
  };
  const errorNotify = () => toast.error('Error');

  const handleEffectChange = () => {
    const body = {
      status: status,
    };
    axios
      .put(`${SERVER}/orders/${orderId}/delivery`, body)
      .then((res) => {
        console.log(res);
        toast.success('Success!');
        handleClose();
        // fetchOrder(id)
      })
      .catch((error) => {
        console.log(error);
        toast.error('Failed!');
        setfail('FAILED! An error occurred');
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      classes={{
        paper: classes.root,
      }}
    >
      <DialogTitle id="simple-dialog-title">Change Order Status</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="status">Select New Status</InputLabel>
          <Select
            native
            value={status}
            label="Select New Status"
            onChange={handleChange}
            inputProps={{
              name: 'status',
              id: 'status',
            }}
          >
            <option aria-label="None" value="" />
            <option value="SHIPPING">Shipping</option>
            <option value="DELIVERED">Delivered</option>
            {/* <option value="ARRIVED">Arrived at pickup station</option> */}
            <option value="CANCELLED">Cancelled</option>
            {/* <option value="RESOLVED">Issue Resolved</option> */}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <BaseBtnFilled onClick={handleEffectChange} className={classes.btn}>
          {' '}
          Change
        </BaseBtnFilled>
        <BaseBtnOutlined onClick={handleClose} className={classes.btn}>
          {' '}
          cancel
        </BaseBtnOutlined>
      </DialogActions>
      {fail && (
        <p className="txt-center txt-bold" style={{ color: 'red' }}>
          {fail}
        </p>
      )}
    </Dialog>
  );
};

ChangeOrderStatus.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
};

export default ChangeOrderStatus;
