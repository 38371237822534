import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import toast, { Toaster } from 'react-hot-toast';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import RiderInfoCard from '../riders/RiderInfoCard';
import BaseBtnFilled from '../BaseBtnFilled';
import BaseBtnOutlined from '../BaseBtnOutlined';

import { fetchRiders, assignRider } from '../../store/rider/riderActions';

const SERVER = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 400,
  },
  actions: {
    justifyContent: 'space-between',
  },
  btn: {
    width: '144px',
  },
}));

const mapState = ({ rider, order }) => ({
  loadingRiders: rider.loadingRiders,
  riders: rider.riders,
  ridersErr: rider.ridersErr,
  orderDetails: order.singleOrder,
});

const AssignRider = ({ handleClose, open, orderId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loadingRiders, riders, ridersErr, orderDetails } = useSelector(
    mapState
  );

  const handleSelectRider = (rider) => {
    const details = { orderId: orderDetails.orderNumber, riderId: rider };
    dispatch(assignRider());
  };

  useEffect(() => {
    dispatch(fetchRiders());

    return () => {};
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      classes={{
        paper: classes.root,
      }}
    >
      <DialogTitle id="simple-dialog-title">Assign Rider</DialogTitle>
      <DialogContent>
        {loadingRiders && <p>LOADING ...</p>}
        {riders &&
          riders.map((item) => (
            <RiderInfoCard
              name={item.name}
              id={item.id}
              phone={item.phoneNumber}
              handleSelect={() => handleSelectRider(item.id)}
            />
          ))}
      </DialogContent>

      {/* {fail && (
        <p className="txt-center txt-bold" style={{ color: 'red' }}>
          {fail}
        </p>
      )} */}
    </Dialog>
  );
};

export default AssignRider;
