import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import './assets/styles/App.css';

import Layout from './pages/dashboard/Layout';
import AuthLayout from './layouts/AuthLayout';
import LoginForm from './pages/auth/LoginForm';
import Welcome from './pages/auth/Welcome';
import ResetPassword from './pages/auth/ResetPassword';
import SignupForm from './pages/auth/SignupForm';
import DashboardHome from './pages/dashboard/DashboardHome';
import Orders from './pages/orders/Orders';
import OrdersHome from './pages/orders/OrdersHome';
import OrdersDetailPage from './pages/orders/OrdersDetailPage';
import OrdersDeliveredPage from './pages/orders/OrdersDeliveredPage';
import OrdersShippingPage from './pages/orders/OrdersShippingPage';
import OrdersCancelledPage from './pages/orders/OrdersCancelledPage';
import OrdersPendingPage from './pages/orders/OrdersPendingPage';
import Transactions from './pages/transactions/Transactions';
import Riders from './pages/riders/Riders';
import RidersHome from './pages/riders/RidersHome';
import Users from './pages/users/Users';
import UsersHome from './pages/users/UsersHome';
import VoucherHome from './pages/vouches/VoucherHome';
import ContextManagement from './pages/content_management/ContextManagement';
import UploadBanner from './pages/content_management/UploadBanner';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<DashboardHome />} />
        <Route path="orders" element={<Orders />}>
          <Route path="" element={<OrdersHome />} />
          <Route path=":id" element={<OrdersDetailPage />} />
          <Route path="pending" element={<OrdersPendingPage />} />
          <Route path="cancelled" element={<OrdersCancelledPage />} />
          <Route path="shipping" element={<OrdersShippingPage />} />
          <Route path="delivered" element={<OrdersDeliveredPage />} />
        </Route>
        <Route path="voucher" element={<VoucherHome/>} />
        <Route path="content_management" element={<ContextManagement/>} />
        <Route path="upload_banner" element={<UploadBanner/>} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="riders" element={<Riders />}>
          <Route path="" element={<RidersHome />} />
        </Route>
        <Route path="users" element={<Users />}>
          <Route path="" element={<UsersHome />} />
        </Route>
      </Route>
      <Route
        path="/auth/login"
        element={
          <AuthLayout>
            <LoginForm />
          </AuthLayout>
        }
      />
      <Route
        path="/auth/welcome"
        element={
          <AuthLayout>
            <Welcome />
          </AuthLayout>
        }
      />
      <Route
        path="/auth/reset-password"
        element={
          <AuthLayout>
            <ResetPassword />
          </AuthLayout>
        }
      />
      {/* <Route
        path="/auth/signup"
        element={
          <AuthLayout>
            <SignupForm />
          </AuthLayout>
        }
      /> */}
    </Routes>
  );
}

export default App;
